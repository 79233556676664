import React, { useCallback, useMemo, useState } from 'react';
import {
	Grid,
	Typography,
	Box,
	Card,
	CardHeader,
	CardContent,
	Divider,
	Chip,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Avatar,
	Tooltip,
	IconButton,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import PersonIcon from '@mui/icons-material/Person';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UpdateIcon from '@mui/icons-material/Update';
// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
import GenericDrawer from '../../Common/GenericDrawer';
import SignatureModal from '../../Common/SignatureModal';
import {
	DeliveryHistoryEntry,
	HistoryEntry,
	IEmployeeWithEpis,
	UpdateHistoryEntry,
} from '../../../containers/EpiControl/EpiControlAssets';
import {
	getEpiStatus, getReplacementTimeLabel, getUpdateActionText, handlePrint, parseHistoryEntry,
} from '../../../helpers/epiControlHelper';

interface IDetailsDrawerProps {
  openDetails?: IEmployeeWithEpis;
  onClose: () => void;
  signature?: string | null;
  getConsumptionSignature: (consumptionId: string) => void;
}

const DetailsDrawer = ({
	openDetails,
	onClose,
	signature,
	getConsumptionSignature,
}: IDetailsDrawerProps): JSX.Element => {
	const [openSignatureModal, setOpenSignatureModal] = useState(false);

	const handleViewSignature = useCallback((consumptionId: string): void => {
		getConsumptionSignature(consumptionId);
		setOpenSignatureModal(true);
	}, [getConsumptionSignature]);

	const handleCloseSignatureModal = useCallback((): void => {
		setOpenSignatureModal(false);
	}, []);

	const contentMemo = useMemo(() => {
		if (!openDetails) {
			return null;
		}

		const episWithDetails = openDetails.ePIControls?.map((epi) => {
			const allHistoryEntries = epi.history
				? epi.history.split('|')
					.map((entry) => entry.trim())
					.map(parseHistoryEntry)
					.filter((entry): entry is HistoryEntry => entry !== null)
				: [];

			const deliveryEntries = allHistoryEntries
				.filter((entry): entry is DeliveryHistoryEntry => entry.type === 'delivery');

			const updateEntries = allHistoryEntries
				.filter((entry): entry is UpdateHistoryEntry => entry.type === 'update');

			return {
				epi,
				deliveryEntries,
				updateEntries,
				hasDeliveryHistory: deliveryEntries.length > 0,
				hasUpdateHistory: updateEntries.length > 0,
			};
		}) || [];

		const episWithDeliveryHistory = episWithDetails
			.filter((item) => item.hasDeliveryHistory);

		const episWithUpdateHistory = episWithDetails
			.filter((item) => item.hasUpdateHistory);

		return (
			<Box id="drawer-content">
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }} className="no-print">
					<Tooltip title="Imprimir Relatório">
						<IconButton
							color="primary"
							onClick={handlePrint(openDetails)}
							size="large"
						>
							<PrintIcon />
						</IconButton>
					</Tooltip>
				</Box>
				<Card variant="outlined" sx={{ mb: 3 }}>
					<CardHeader
						avatar={(
							<Avatar sx={{ bgcolor: 'primary.main' }}>
								<PersonIcon />
							</Avatar>
						)}
						title={(
							<Typography variant="h5" component="div">
								{openDetails.fullName}
							</Typography>
						)}
						subheader={`Matrícula: ${openDetails.registration}`}
					/>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<Typography variant="subtitle2" color="text.secondary">
									Departamento
								</Typography>
								<Typography variant="body1">
									{openDetails.department}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography variant="subtitle2" color="text.secondary">
									Cargo
								</Typography>
								<Typography variant="body1">
									{openDetails.position}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography variant="subtitle2" color="text.secondary">
									Data de Admissão
								</Typography>
								<Typography variant="body1">
									{format(new Date(openDetails.admissionDate), 'dd/MM/yyyy', { locale: ptBR })}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography variant="subtitle2" color="text.secondary">
									Centro de Custo
								</Typography>
								<Typography variant="body1">
									{openDetails.costCenter}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography variant="subtitle2" color="text.secondary">
									Empresa de Registro
								</Typography>
								<Typography variant="body1">
									{openDetails.registrationCompanyName}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography variant="subtitle2" color="text.secondary">
									Filial de Registro
								</Typography>
								<Typography variant="body1">
									{openDetails.registrationBranchName}
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
				<Card variant="outlined">
					<CardHeader
						avatar={(
							<Avatar sx={{ bgcolor: 'secondary.main' }}>
								<HealthAndSafetyIcon />
							</Avatar>
						)}
						title={(
							<Typography variant="h5" component="div">
								EPIs Associados
							</Typography>
						)}
						subheader={`Total: ${openDetails.ePIControls?.length || 0} EPIs`}
					/>
					<Divider />
					<CardContent>
						{openDetails.ePIControls && openDetails.ePIControls.length > 0 ? (
							<TableContainer component={Paper} variant="outlined">
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>Código</TableCell>
											<TableCell>Produto</TableCell>
											<TableCell align="center">Unidade</TableCell>
											<TableCell align="center">Qtd.</TableCell>
											<TableCell align="center">Última Entrega</TableCell>
											<TableCell align="center">Substituição</TableCell>
											<TableCell align="center">Status</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{openDetails.ePIControls.map((epi) => {
											const statusInfo = getEpiStatus(epi);
											const replacementInfo = epi.replacementTime
												? `${epi.replacementTime} ${getReplacementTimeLabel(epi.replacementTimeUnit)}`
												: 'Não definido';

											return (
												<TableRow key={epi.id} hover>
													<TableCell>{epi.productCode}</TableCell>
													<TableCell>{epi.productDescription}</TableCell>
													<TableCell align="center">{epi.primaryMeasureDescription}</TableCell>
													<TableCell align="center">{epi.quantity}</TableCell>
													<TableCell align="center">
														{epi.lastReplacement ? format(new Date(epi.lastReplacement), 'dd/MM/yyyy HH:mm', { locale: ptBR }) : '-'}
													</TableCell>
													<TableCell align="center">{replacementInfo}</TableCell>
													<TableCell align="center">
														<Chip
															label={statusInfo.status}
															color={statusInfo.color as 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'}
															size="small"
															variant="outlined"
															sx={{
																height: '22px',
																width: '150px',
															}}
														/>
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<Box sx={{ textAlign: 'center', py: 4 }}>
								<Typography variant="subtitle1" color="text.secondary">
									Este colaborador não possui EPIs associados.
								</Typography>
							</Box>
						)}
					</CardContent>
				</Card>
				{episWithDeliveryHistory.length > 0 && (
					<Card variant="outlined" sx={{ mt: 3 }}>
						<CardHeader
							avatar={(
								<Avatar sx={{ bgcolor: 'info.main' }}>
									<EditCalendarIcon />
								</Avatar>
							)}
							title={(
								<Typography variant="h5" component="div">
									Histórico de Entregas de EPI
								</Typography>
							)}
							subheader={`${episWithDeliveryHistory.length} EPIs com histórico de entrega`}
						/>
						<Divider />
						<CardContent>
							<Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
								{episWithDeliveryHistory.map((item, index) => (
									<Box key={item.epi.id}>
										<Typography variant="button" gutterBottom>
											{item.epi.productCode}
											{' '}
											-
											{item.epi.productDescription}
										</Typography>
										<TableContainer component={Paper} variant="outlined">
											<Table size="small">
												<TableHead>
													<TableRow>
														<TableCell>Data de Entrega</TableCell>
														<TableCell>EPI</TableCell>
														<TableCell>Consumo</TableCell>
														<TableCell align="right">Assinatura</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{item.deliveryEntries.map((entry, entryIndex) => (
														// eslint-disable-next-line react/no-array-index-key
														<TableRow key={`${(entry as DeliveryHistoryEntry).consumptionId}-${entryIndex}`}>
															<TableCell>{(entry as DeliveryHistoryEntry).date}</TableCell>
															<TableCell>
																{(entry as DeliveryHistoryEntry).epiCode}
																{' '}
																-
																{' '}
																{(entry as DeliveryHistoryEntry).epiDescription}
															</TableCell>
															<TableCell>
																{(entry as DeliveryHistoryEntry)
																	.consumptionCode}
															</TableCell>
															<TableCell align="right">
																<Tooltip title="Visualizar assinatura">
																	<IconButton
																		color="primary"
																		size="small"
																		onClick={() => handleViewSignature(
																			(entry as DeliveryHistoryEntry)
																				.consumptionId,
																		)}
																	>
																		<EditCalendarIcon fontSize="small" />
																	</IconButton>
																</Tooltip>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
										{index < episWithDeliveryHistory.length - 1 && (
											<Divider sx={{ my: 2 }} />
										)}
									</Box>
								))}
							</Box>
						</CardContent>
					</Card>
				)}
				{episWithUpdateHistory.length > 0 && (
					<Accordion sx={{ mt: 3 }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="update-history-content"
							id="update-history-header"
							sx={{
								display: 'flex',
								alignItems: 'center',
								bgcolor: 'background.paper',
								borderRadius: 1,
							}}
						>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Avatar sx={{
									bgcolor: 'warning.main', mr: 2, width: 40, height: 40,
								}}
								>
									<UpdateIcon />
								</Avatar>
								<Box>
									<Typography variant="h6" component="div">
										Histórico de Atualizações de EPI
									</Typography>
									<Typography variant="body2" color="text.secondary">
										{episWithUpdateHistory.length}
										{' '}
										EPIs com histórico de atualizações
									</Typography>
								</Box>
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							<Box sx={{
								display: 'flex', flexDirection: 'column', gap: 3, mt: 2,
							}}
							>
								{episWithUpdateHistory.map((item, index) => (
									<Box key={item.epi.id}>
										<Typography variant="button" gutterBottom>
											{item.epi.productCode}
											{' '}
											-
											{item.epi.productDescription}
										</Typography>
										<TableContainer component={Paper} variant="outlined">
											<Table size="small">
												<TableHead>
													<TableRow>
														<TableCell>Data</TableCell>
														<TableCell>Ação</TableCell>
														<TableCell>Usuário</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{item.updateEntries.map((entry, entryIndex) => {
														const updateEntry = entry as UpdateHistoryEntry;
														const actionText = getUpdateActionText(updateEntry.action);

														return (
														// eslint-disable-next-line react/no-array-index-key
															<TableRow key={`update-${item.epi.id}-${entryIndex}`}>
																<TableCell>{updateEntry.date}</TableCell>
																<TableCell>
																	{actionText}
																</TableCell>
																<TableCell>{updateEntry.user}</TableCell>
															</TableRow>
														);
													})}
												</TableBody>
											</Table>
										</TableContainer>
										{index < episWithUpdateHistory.length - 1 && (
											<Divider sx={{ my: 2 }} />
										)}
									</Box>
								))}
							</Box>
						</AccordionDetails>
					</Accordion>
				)}
			</Box>
		);
	}, [handleViewSignature, openDetails]);

	return (
		<>
			<GenericDrawer
				open={Boolean(openDetails)}
				content={contentMemo}
				onClose={onClose}
				width={1000}
			/>
			<SignatureModal
				open={openSignatureModal}
				onClose={handleCloseSignatureModal}
				signature={signature}
			/>
		</>
	);
};

DetailsDrawer.defaultProps = {
	openDetails: undefined,
	signature: undefined,
};

export default DetailsDrawer;
