import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import EpiControlPresentational from '../../components/EpiControl/EpiControlEdit';
import {
	getEmployeeWithEpiById,
	removeEpiControl,
	upsertEmployeeEpi,
	handleActive,
} from '../../services/epiControl';
import {
	IEmployeeWithEpi,
	UpsertEmployeeEpi,
} from './EpiControlAssets';
import { getEPIProducts } from '../../services/product';
import { IProductEPI } from '../Product/ProductAssets';
import { ProductEPIQueryParams } from '../../interfaces/ProductEpiQueryParams';

  enum ActionType {
	LOADING,
	EMPLOYEE_WITH_EPI,
	EPI_PRODUCTS,
  }

  interface IState {
	loading: boolean;
	employeeWithEpi?: IEmployeeWithEpi;
	epiProducts?: IProductEPI[];
  }

  type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.EMPLOYEE_WITH_EPI; payload: { employeeWithEpi: IEmployeeWithEpi } }
	| { type: ActionType.EPI_PRODUCTS; payload: { epiProducts: IProductEPI[] } };

  interface IEpiControlActions {
	setLoading(loading: boolean): void;
	getEmployeeWithEpiById: (employeeId: string, active?: boolean) => void;
	upsertEmployeeEpi(employeeId: string, data: UpsertEmployeeEpi): void;
	handleDelete(id: string): void;
	handleActive: (id: string, active: boolean) => Promise<any>;
	getEPIProducts(queryParams?: ProductEPIQueryParams): void;
  }

const initialState: IState = {
	loading: false,
	employeeWithEpi: undefined,
	epiProducts: undefined,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.EMPLOYEE_WITH_EPI:
			return { ...state, employeeWithEpi: action.payload.employeeWithEpi };
		case ActionType.EPI_PRODUCTS:
			return { ...state, epiProducts: action.payload.epiProducts };
		default:
			throw new Error();
	}
};

let savedEmployeeId: string;

const EpiControlActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (
	message: SnackbarMessage,
	options?: OptionsObject | undefined
	) => SnackbarKey,
): IEpiControlActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getEmployeeWithEpiById(employeeId: string, active?: boolean) {
			actions.setLoading(true);
			getEmployeeWithEpiById(employeeId, active)
				.then((response) => {
					dispatch({
						type: ActionType.EMPLOYEE_WITH_EPI,
						payload: { employeeWithEpi: response.data },
					});
					savedEmployeeId = employeeId;
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao obter detalhes do colaborador e suas EPIs',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		getEPIProducts(queryParams?: ProductEPIQueryParams) {
			getEPIProducts(queryParams)
				.then((response) => {
					dispatch({
						type: ActionType.EPI_PRODUCTS,
						payload: { epiProducts: response.data.data },
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao obter produtos EPI',
						{ variant: 'error' },
					);
				});
		},
		upsertEmployeeEpi(employeeId: string, data: UpsertEmployeeEpi) {
			actions.setLoading(true);
			upsertEmployeeEpi(employeeId, data)
				.then((response) => {
					actions.getEmployeeWithEpiById(employeeId);
					enqueueSnackbar(response.data.message || 'EPIs atualizados com sucesso', { variant: 'success' });
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao atualizar EPIs do colaborador',
						{ variant: 'error' },
					);
					actions.setLoading(false);
				});
		},
		handleDelete(id: string) {
			removeEpiControl(id)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Controle de EPI removido com sucesso',
						{ variant: 'success' },
					);
					actions.getEmployeeWithEpiById(savedEmployeeId);
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao remover controle de EPI',
						{ variant: 'error' },
					);
				});
		},
		handleActive(id: string, active: boolean) {
			return handleActive({ id, active })
				.then((response) => {
					enqueueSnackbar(
						response.data.message || `EPI ${active ? 'ativado' : 'desativado'} com sucesso`,
						{ variant: 'success' },
					);
					return response;
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || `Erro ao ${active ? 'ativar' : 'desativar'} o EPI`,
						{ variant: 'error' },
					);
					throw error;
				});
		},
	};

	return actions;
};

const EpiControlEdit = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => EpiControlActions(dispatch, enqueueSnackbar),
		[dispatch, enqueueSnackbar],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <EpiControlPresentational {...state} {...actions} />;
};

export default EpiControlEdit;
