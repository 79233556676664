import { AxiosResponse } from 'axios';
import api from './api';
import { IMessageResponse } from '../interfaces/MessageResponse';
import {
	EpiControlResponse,
	CreateEpiControlParams,
	EditEpiControl,
	UpsertEmployeeEpi,
	IEmployeeWithEpi,
} from '../containers/EpiControl/EpiControlAssets';
import { EpiControlQueryParams } from '../interfaces/EpiControlQueryParams';

export const createEpiControl = (
	data: CreateEpiControlParams,
): Promise<AxiosResponse<IMessageResponse>> => api.post<CreateEpiControlParams, AxiosResponse<IMessageResponse>>('/epi-control', data);

export const handleActive = (
	data: { id: string; active: boolean },
): Promise<AxiosResponse<IMessageResponse>> => api.post<{ id: string; active: boolean }, AxiosResponse<IMessageResponse>>('/epi-control/handleActive', data);

export const getAllEmployeesWithEpis = (
	params?: EpiControlQueryParams,
): Promise<AxiosResponse<EpiControlResponse>> => api.get<EpiControlResponse>('/epi-control', { params });

export const getEmployeeWithEpiById = (
	employeeId: string,
	active?: boolean,
): Promise<AxiosResponse<IEmployeeWithEpi>> => api.get<IEmployeeWithEpi>(`/epi-control/${employeeId}`, { params: { active } });

export const upsertEmployeeEpi = (
	employeeId: string,
	data: UpsertEmployeeEpi,
): Promise<AxiosResponse<IMessageResponse>> => api.patch<UpsertEmployeeEpi, AxiosResponse<IMessageResponse>>(`/epi-control/employee-epi/${employeeId}`, data);

export const updateEpiControl = (
	id: string,
	data: EditEpiControl,
): Promise<AxiosResponse<IMessageResponse>> => api.patch<EditEpiControl, AxiosResponse<IMessageResponse>>(`/epi-control/${id}`, data);

export const removeEpiControl = (
	id: string,
): Promise<AxiosResponse<IMessageResponse>> => api.delete<IMessageResponse>(`/epi-control/${id}`);
