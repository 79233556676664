import { AxiosResponse } from 'axios';
import api from './api';
import {
	EmployeeQueryParams,
	GetEmployeesResponse,
	IEmployee,
	IEmployeeResponse,
} from '../containers/Employee/EmployeeAssets';
import { IMessageResponse } from '../interfaces/MessageResponse';

export const getEmployees = (params: EmployeeQueryParams): Promise<AxiosResponse<GetEmployeesResponse>> => api.get<GetEmployeesResponse>('/employee', { params });

export const createEmployeesFromXls = (data: FormData): Promise<AxiosResponse<IEmployeeResponse>> => api.post('/employee/create-employees-from-xls', data, {
	headers: { 'Content-Type': 'multipart/form-data' },
});

export const downloadEmployeeXls = (): Promise<AxiosResponse<Blob>> => api.get('/employee/download-xls', {
	responseType: 'blob',
});

export const deleteEmployee = (employeeId: string): Promise<AxiosResponse<{ message: string }>> => api.delete<{ message: string }>(`/employee/${employeeId}`);

export const getEmployeeById = (id: string): Promise<AxiosResponse<IEmployee>> => api.get<IEmployee>(`/employee/${id}`);

export const createEmployee = (data: IEmployee): Promise<AxiosResponse<IMessageResponse>> => api.post<IEmployee, AxiosResponse<IMessageResponse>>('/employee', data);

export const updateEmployee = (id: string, data: Partial<IEmployee>): Promise<AxiosResponse<IMessageResponse>> => api.patch<Partial<IEmployee>, AxiosResponse<IMessageResponse>>(`/employee/${id}`, data);

export const createEmployeesBatch = (data: IEmployee[]): Promise<AxiosResponse<IMessageResponse>> => api.post<IEmployee[], AxiosResponse<IMessageResponse>>('/employee/batch', data);
