/* eslint-disable import/no-duplicates */
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { IEmployeeWithEpis } from '../containers/EpiControl/EpiControlAssets';

export const epiControlColumns: GridColDef[] = [
	{
		field: 'registrationCompanyName',
		headerName: 'Empresa de Registro',
		flex: 1,
	},
	{
		field: 'registrationBranchName',
		headerName: 'Filial de Registro',
		flex: 1,
	},
	{
		field: 'registration',
		headerName: 'Matrícula',
		flex: 0.5,
	},
	{
		field: 'fullName',
		headerName: 'Nome Completo',
		flex: 1,
	},
	{
		field: 'department',
		headerName: 'Departamento',
		flex: 1,
	},
	{
		field: 'position',
		headerName: 'Cargo',
		flex: 1,
	},
	{
		field: 'epiCount',
		headerName: 'EPIs Associados',
		type: 'number',
		headerAlign: 'center',
		align: 'center',
		flex: 1,
		valueGetter: (params) => {
			const employee = params.row as IEmployeeWithEpis;
			return employee.ePIControls ? employee.ePIControls.length : 0;
		},
	},
	{
		field: 'lastReplacementEpi',
		headerName: 'Última Entrega',
		flex: 1,
		valueFormatter: (params: GridValueFormatterParams) => (params.value ? format(new Date(params.value), 'dd/MM/yyyy HH:mm', { locale: ptBR }) : '-'),
	},
	{
		field: 'admissionDate',
		headerName: 'Data de Admissão',
		flex: 1,
		valueFormatter: (params: GridValueFormatterParams) => (params.value ? format(new Date(params.value), 'dd/MM/yyyy', { locale: ptBR }) : '-'),
	},
	{
		field: 'costCenter',
		headerName: 'Centro de Custo',
		flex: 1,
	},
];
