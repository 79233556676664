import React, {
	useCallback, useState, useMemo, useEffect,
} from 'react';
import { Box, Paper } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Link } from 'react-router-dom';
import { DataGrid, GridSortDirection } from '@mui/x-data-grid';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import {
	EmployeeQueryParams, IEmployee, IEmployeeError,
} from '../../containers/Employee/EmployeeAssets';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import EmployeeXLSModal from './Modals/EmployeeXLSModal';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { IBranch } from '../../containers/Branch/BranchAssets';
import DrawerFilter from '../Common/DrawerFilter';
import EmployeeFilter from './EmployeeFilter';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';
import { ToolbarComponent } from '../Common/Datagrid/DataGridToolBar';
import { useManageColumns } from '../../hooks/useManageColumns';
import useColumns from '../../hooks/useColumns';
import { employeeColumns } from '../../constants/employeeColumns';
import { normalizeDataGridOrderBy } from '../../helpers/Utils';

const localStorageKey = 'employee';

const columnsVisibility = [
	'registration',
	'fullName',
	'birthDate',
	'admissionDate',
	'department',
	'costCenter',
	'position',
	'registrationCompanyName',
	'registrationBranchName',
	'actions',
];

interface EmployeeProps {
	loading: boolean;
	employees: IEmployee[];
	pages: number;
	page: number;
	take: number;
	getEmployees(params: EmployeeQueryParams): void;
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
	employeesWithError: IEmployeeError[];
	getCompanies(): void;
	getBranches: (companyId: string) => void;
	downloadEmployeeXls(): void;
	deleteEmployee: (employeeId: string) => void;
	handleEdit:(id: string) => void;
	existingEmployees: IEmployee[];
	successMessage: string;
	createEmployeesFromXls(
		data: FormData
	): void;
	setExistingEmployees(existingEmployees: IEmployee[]): void;
	createEmployeesBatch(data: IEmployee[]): void;
	setEmployeesWithError(employeesWithError: IEmployeeError[]): void;
}

const Employee = ({
	loading,
	employees,
	pages,
	page,
	take,
	companies,
	branches,
	employeesWithError,
	getEmployees,
	getCompanies,
	getBranches,
	downloadEmployeeXls,
	deleteEmployee,
	handleEdit,
	existingEmployees,
	successMessage,
	createEmployeesFromXls,
	setExistingEmployees,
	createEmployeesBatch,
	setEmployeesWithError,
}: EmployeeProps): JSX.Element => {
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [filterValues] = useState<Partial<EmployeeQueryParams>>({});

	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const {
		setFilter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement({
		initialPageSize: take,
		initialPage: page,
		fetchData: getEmployees,
	});

	const handleDelete = useCallback((id: string) => {
		requestConfirm({
			description: 'Tem certeza que deseja excluir o colaborador selecionado?',
			callback: () => deleteEmployee(id),
		});
	}, [deleteEmployee, requestConfirm]);

	const { columns, defaultVisibility } = useColumns({
		columnsVisibility,
		defaultColumnsOverride: employeeColumns,
		onEditClick: handleEdit,
		onDeleteClick: handleDelete,
	});

	const {
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
	} = useManageColumns({
		initialColumns: columns,
		initialVisibility: defaultVisibility,
		localStorageKey,
		isDraggable: true,
	});

	const [isModalOpen, setModalOpen] = useState(false);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen(!isFilterDrawerOpen);
	}, [isFilterDrawerOpen]);

	const handleOpenModal = useCallback(() => {
		setModalOpen(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setModalOpen(false);
		setExistingEmployees([]);
		setEmployeesWithError([]);
	}, [setEmployeesWithError, setExistingEmployees]);

	const headerButtons: PageHeaderButtonProps[] = [
		{
			variant: 'contained',
			color: 'primary',
			type: 'submit',
			fullWidth: true,
			component: Link,
			to: 'edit',
			text: 'Novo',
		},
		{
			variant: 'contained',
			onClick: handleOpenModal,
			text: 'Importar Colaboradores',
			startIcon: <FileUploadIcon />,
		},
		{
			variant: 'contained',
			onClick: downloadEmployeeXls,
			text: 'Baixar Modelo',
			startIcon: <FileDownloadIcon />,
		},
		{
			variant: 'contained',
			onClick: toggleFilterDrawer,
			text: 'Filtro',
			startIcon: <FilterListIcon />,
		},
	];

	const sendFilter = useCallback((values: Partial<EmployeeQueryParams>) => {
		const pageFilter = {
			...values,
			skip: 0,
			orderBy: normalizeDataGridOrderBy(sortModel) as {
					name: GridSortDirection,
					code: GridSortDirection,
				},
		};
		setFilter(pageFilter);
		setFilterDrawerOpen(false);
	}, [setFilter, sortModel]);

	const employeeFilterMemo = useMemo(() => (
		<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
			<EmployeeFilter
				initialValues={filterValues}
				sendFilter={sendFilter}
				companies={companies}
				branches={branches}
				getCompanies={getCompanies}
				getBranches={getBranches}
			/>
		</DrawerFilter>
	), [
		branches,
		companies,
		filterValues,
		isFilterDrawerOpen,
		toggleFilterDrawer,
		getBranches,
		getCompanies,
		sendFilter,
	]);

	const employeeXLSModalMemo = useMemo(() => (
		<EmployeeXLSModal
			open={isModalOpen}
			handleClose={handleCloseModal}
			loading={loading}
			companies={companies}
			branches={branches}
			employeesWithError={employeesWithError}
			getCompanies={getCompanies}
			getBranches={getBranches}
			successMessage={successMessage}
			createEmployeesFromXls={createEmployeesFromXls}
			existingEmployees={existingEmployees}
			createEmployeesBatch={createEmployeesBatch}
		/>
	), [
		isModalOpen,
		handleCloseModal,
		loading,
		companies,
		branches,
		employeesWithError,
		getCompanies,
		getBranches,
		successMessage,
		createEmployeesFromXls,
		existingEmployees,
		createEmployeesBatch,
	]);

	return (
		<>
			<Box className="content">
				{employeeFilterMemo}
				{employeeXLSModalMemo}
				<PageHeader
					title="Colaborador"
					buttons={headerButtons}
				/>
				<Paper>
					<DataGrid
						autoHeight
						rows={employees}
						rowCount={pages}
						pagination
						paginationMode="server"
						sortingMode="server"
						columns={currentColumns}
						page={page}
						pageSize={take}
						rowsPerPageOptions={[10, 25, 50, 100]}
						loading={loading}
						sortModel={sortModel}
						columnVisibilityModel={columnVisibilityModel}
						onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
						components={{ Toolbar: ToolbarComponent(onRestoreClick) }}
						onPageChange={onChangePage}
						onPageSizeChange={onChangePageSize}
						onSortModelChange={onSortModelChange}
						disableSelectionOnClick
						disableColumnMenu
					/>
				</Paper>
			</Box>
			{confirmationDialog}
		</>
	);
};

export default Employee;
