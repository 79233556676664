import React, { useState, useCallback } from 'react';
import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Stack,
	Box,
	Typography,
	useTheme,
} from '@mui/material';
import {
	createStatusTaskMap,
	defaultFilterContextScreen,
} from '../../constants/inventoryTask';
import { SelectedTasks } from './ConsumptionTask';
import { InventoryTaskStatus } from '../../enums/InventoryTaskStatus';
import { InventoryTaskAction } from '../../enums/InventoryTaskAction';
import Pagination from '../Common/Pagination';
import { ExpandableRow } from './ExpandedConsumptionDetails';
import { ConsumptionProductionTaskQueryParams } from '../../interfaces/ConsumptionProductionTaskQueryParams';
import { IConsumption, ConsumptionProductionTask } from '../../containers/Consumption/ConsumptionAssets';
import { StatusTaskMap } from '../../containers/InventoryTaskInvoice/InventoryTaskInvoiceAssets';
import {
	stickyHeaderStyle,
	tableBodyStyle,
	tableContainerStyle,
	tableRowStyle,
} from '../../helpers/tableStylesHelper';

export const colWidths = [
	'3%',
	'32%',
	'30%',
	'32%',
	'6%',
];

interface ConsumptionTableProps {
  tasksPages: number;
  tasksPage: number;
  tasksTake: number;
  loading: boolean;
  consumptions: IConsumption[];
  selectedRows: SelectedTasks;
  handleSelectedRowsChange: (rowIndex: number) => void;
  handlerChangeProduct: (
    rowIndex: number,
    indexProduct: number,
    checked: boolean
  ) => void;
  onChangePage(page: number): void;
  onChangePageSize(pageSize: number): void;
  rowsIndex: number[];
  filter: ConsumptionProductionTaskQueryParams;
  handlerFinishTask: (inventoryTasksIds: Array<string>) => void;
}

const ConsumptionTable = ({
	loading,
	consumptions,
	selectedRows,
	filter,
	rowsIndex,
	handleSelectedRowsChange,
	onChangePage,
	onChangePageSize,
	handlerChangeProduct,
	handlerFinishTask,
	tasksPages,
	tasksPage,
	tasksTake,
}: ConsumptionTableProps): JSX.Element => {
	const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
	const theme = useTheme();

	const handleRowExpand = useCallback((id: string) => {
		setExpandedRows((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	}, []);

	const isRowEnabled = useCallback(
		(id: string) => {
			const consumption = consumptions.find((c) => c.id === id);
			if (!consumption) return false;

			return consumption.tasks.some((task) => task.status === InventoryTaskStatus.PENDING
			&& Boolean(task.locationOriginBarCode)
			&& Boolean(task.locationDestinyBarCode));
		},
		[consumptions],
	);

	const isCheckedProductEnabled = useCallback(
		(
			status: InventoryTaskStatus,
			locationDestinyBarCode?: string,
			locationOriginBarCode?: string,
		) => status === InventoryTaskStatus.PENDING
           && Boolean(locationOriginBarCode)
           && Boolean(locationDestinyBarCode),
		[],
	);

	const handleMenuItemClick = useCallback(
		(
			indexItemSelected: number,
			consumptionTask: ConsumptionProductionTask,
		) => {
			const isFinishTask = indexItemSelected === 0;

			if (isFinishTask) {
				const { id } = consumptionTask;
				handlerFinishTask([id]);
			}
		},
		[handlerFinishTask],
	);

	const isChecked = useCallback(
		(consumption: IConsumption, task: ConsumptionProductionTask) => {
			const tasksData = selectedRows[consumption.id]?.tasksIds ?? [];
			return tasksData.includes(task.id);
		},
		[selectedRows],
	);

	const buildLabelStatusTask = useCallback(
		(status: InventoryTaskStatus) => {
			let statusMap: StatusTaskMap = {} as StatusTaskMap;
			const type = filter.type !== undefined ? filter.type : defaultFilterContextScreen;

			switch (type) {
				case InventoryTaskAction.CONSUMPTION:
					statusMap = createStatusTaskMap({
						operatingLabel: 'Em Andamento',
					});
					break;
				default:
					statusMap = createStatusTaskMap({ operatingLabel: 'Em Armazenagem' });
			}
			return statusMap[status]?.label || 'Status Desconhecido';
		},
		[filter.type],
	);

	const handleRowSelect = useCallback(
		(rowIndex: number) => {
			handleSelectedRowsChange(rowIndex);
		},
		[handleSelectedRowsChange],
	);

	const selectedItems = rowsIndex.map((index) => consumptions[index]?.id || '').filter((id) => id);

	const handlePaginationChange = useCallback(
		(page: number, take: number) => {
			onChangePage(page - 1);
			onChangePageSize(take);
		},
		[onChangePage, onChangePageSize],
	);

	return (
		<>
			<TableContainer sx={tableContainerStyle(theme)}>
				<Table
					stickyHeader
					aria-label="collapsible table"
					sx={stickyHeaderStyle(theme)}
				>
					<TableHead>
						<TableRow sx={tableRowStyle(theme)}>
							<TableCell padding="checkbox" style={{ width: colWidths[0], minWidth: colWidths[0] }} />
							<TableCell style={{ width: colWidths[1], minWidth: colWidths[1] }}>
								Solicitante
							</TableCell>
							<TableCell style={{ width: colWidths[2], minWidth: colWidths[2] }}>Código</TableCell>
							<TableCell style={{ width: colWidths[3], minWidth: colWidths[3] }}>Data</TableCell>
							<TableCell style={{ width: colWidths[4] }} />
						</TableRow>
					</TableHead>
					<TableBody
						sx={tableBodyStyle(theme)}
					>
						{loading && (
							<TableRow>
								<TableCell colSpan={6} align="center">
									<Stack direction="row" justifyContent="center" p={2}>
										<Typography>Carregando...</Typography>
									</Stack>
								</TableCell>
							</TableRow>
						)}
						{!loading && consumptions.length > 0 && (
							consumptions.map((consumption, index) => (
								<ExpandableRow
									key={consumption.id}
									consumption={consumption}
									isSelected={selectedItems.includes(consumption.id)}
									isRowEnabled={isRowEnabled(consumption.id)}
									onSelect={() => handleRowSelect(index)}
									onExpand={() => handleRowExpand(consumption.id)}
									isExpanded={expandedRows[consumption.id] || false}
									consumptions={consumptions}
									isChecked={isChecked}
									isCheckedProductEnabled={isCheckedProductEnabled}
									handlerChangeProduct={handlerChangeProduct}
									buildLabelStatusTask={buildLabelStatusTask}
									handleMenuItemClick={handleMenuItemClick}
									index={index}
								/>
							))
						)}
						{!loading && consumptions.length === 0 && (
							<TableRow>
								<TableCell colSpan={6} align="center">
									<Typography p={2}>Nenhum registro encontrado</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Box sx={{ mt: 2, mb: 1 }}>
				<Pagination
					loading={loading}
					count={tasksPages}
					page={tasksPage + 1}
					take={tasksTake}
					onChange={handlePaginationChange}
				/>
			</Box>
		</>
	);
};

export default ConsumptionTable;
