import React, { useCallback, useEffect, useMemo } from 'react';
import {
	Box,
	Theme,
	Card,
	CardActionArea,
	CardContent,
	Typography,
	Stack,
	Icon,
	Paper,
	Collapse,
	Button,
	Grid,
} from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import PieChartIcon from '@mui/icons-material/PieChart';
import SettingsIcon from '@mui/icons-material/Settings';
import AppsIcon from '@mui/icons-material/Apps';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Avatar from '@mui/material/Avatar';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import { SxProps } from '@mui/system';
import { Link } from 'react-router-dom';
import { hasPermission } from '../../helpers/permission';
import { adminModules, moduleGroups } from '../../constants/menu';
import { useNavigationContext } from '../../contexts/NavigationContext';
import { getGreeting } from '../../helpers/Utils';
import useModuleManager from '../../hooks/useModuleManager';
import useCurrentUser from '../../hooks/useCurrentUser';

const homeContainer: SxProps<Theme> = {
	width: '100%',
};

const sectionContainer: SxProps<Theme> = {
	display: 'flex',
	flexWrap: 'wrap',
	gap: '1rem',
	justifyContent: 'flex-start',
	padding: '1rem',
};

const actionContent: SxProps<Theme> = {
	height: '100%',
};

const content: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	rowGap: '1rem',
	width: 150,
	p: 2,
};

const adminContent: SxProps<Theme> = {
	...content,
	width: 130,
	p: 1.5,
};

const sectionTitle: SxProps<Theme> = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: '0.5rem',
	px: 2,
	py: 1,
	borderBottom: '1px solid',
	borderColor: 'divider',
	backgroundColor: 'background.paper',
};

const subsectionTitle: SxProps<Theme> = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '100%',
	px: 2,
	py: 1,
	backgroundColor: 'rgba(0, 0, 0, 0.02)',
	borderRadius: 1,
	marginBottom: '0.5rem',
	cursor: 'pointer',
	transition: 'background-color 0.2s',
	'&:hover': {
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
	},
};

const categoryCard: SxProps<Theme> = {
	height: 200,
	width: 300,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	transition: 'transform 0.2s ease-in-out',
	'&:hover': {
		transform: 'scale(1.03)',
	},
};

const categoryIcon: React.CSSProperties = {
	fontSize: '4rem',
	marginBottom: '1rem',
};

const welcomePaper: SxProps<Theme> = {
	p: 3,
	borderRadius: 2,
	background: 'linear-gradient(to right, rgba(240,249,255,1) 0%, rgba(229,243,255,1) 100%)',
	display: 'flex',
	flexDirection: { xs: 'column', sm: 'row' },
	alignItems: 'center',
	gap: 3,
};

const welcomeAvatar: SxProps<Theme> = {
	width: 64,
	height: 64,
	bgcolor: 'primary.main',
	fontSize: '1.5rem',
	boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
};

const handAnimation = {
	mr: 1,
	color: 'warning.main',
	animation: 'wave 1.8s infinite',
	'@keyframes wave': {
		'0%': { transform: 'rotate(0deg)' },
		'10%': { transform: 'rotate(14deg)' },
		'20%': { transform: 'rotate(-8deg)' },
		'30%': { transform: 'rotate(14deg)' },
		'40%': { transform: 'rotate(-4deg)' },
		'50%': { transform: 'rotate(10deg)' },
		'60%': { transform: 'rotate(0deg)' },
		'100%': { transform: 'rotate(0deg)' },
	},
};

const companyName = localStorage.getItem('name');

const Home = (): JSX.Element => {
	const {
		menu,
		hasAdminAccess,
		hasRegularModuleAccess,
		accessFlags,
		getGroupModules,
		expandedGroups,
		toggleGroup,
	} = useModuleManager();

	const { expandedSection, syncNavigationState } = useNavigationContext();
	const { userName, userInitials } = useCurrentUser();

	useEffect(() => {
		if (!expandedSection && (!hasAdminAccess || !hasRegularModuleAccess)) {
			syncNavigationState('applications');
		}
	}, [expandedSection, hasAdminAccess, hasRegularModuleAccess, syncNavigationState]);

	const handleExpandSection = useCallback((section: string): void => {
		syncNavigationState(section);
	}, [syncNavigationState]);

	const handleCollapseSection = useCallback((): void => {
		syncNavigationState('/');
	}, [syncNavigationState]);

	const adminModulesCards = useMemo(
		() => menu
			.filter((module) => adminModules.includes(module.code))
			.map((module) => (
				<Card key={module.code} elevation={1} sx={{ borderRadius: 2 }}>
					<CardActionArea component={Link} to={module.link} sx={actionContent}>
						<CardContent sx={adminContent}>
							<Icon
								fontSize="medium"
								color="primary"
								style={{ fontSize: '2.5rem' }}
							>
								{module.icon}
							</Icon>
							<Typography
								variant="body2"
								component="span"
								textAlign="center"
							>
								{module.name}
							</Typography>
						</CardContent>
					</CardActionArea>
				</Card>
			)),
		[menu],
	);

	const createGroupModuleCards = useCallback(
		(groupModules: string[]): JSX.Element[] => getGroupModules(groupModules).map((module) => (
			<Card key={module.code} elevation={2} sx={{ borderRadius: 2 }}>
				<CardActionArea component={Link} to={module.link} sx={actionContent}>
					<CardContent sx={content}>
						<Icon
							fontSize="large"
							color="primary"
							style={{ fontSize: '3rem' }}
						>
							{module.icon}
						</Icon>
						<Typography
							variant="subtitle1"
							component="span"
							textAlign="center"
						>
							{module.name}
						</Typography>
					</CardContent>
				</CardActionArea>
			</Card>
		)),
		[getGroupModules],
	);

	return (
		<Stack spacing={4} sx={{ mt: 2 }}>
			<Paper
				elevation={0}
				sx={welcomePaper}
			>
				<Avatar
					sx={welcomeAvatar}
				>
					{userInitials}
				</Avatar>
				<Box sx={{ flexGrow: 1 }}>
					<Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
						<WavingHandIcon
							sx={handAnimation}
						/>
						<Typography variant="h4" component="h1" fontWeight="500">
							{getGreeting()}
							,
							{' '}
							{userName}
							!
						</Typography>
					</Box>
					<Typography variant="body1" color="text.secondary">
						Seja bem-vindo(a) ao Portal
						{' '}
						{companyName}
						. O que você deseja fazer hoje?
					</Typography>
				</Box>
			</Paper>
			<Box sx={homeContainer}>
				{!expandedSection && hasAdminAccess && hasRegularModuleAccess && (
					<Grid container spacing={3} justifyContent="center" sx={{ py: 4 }}>
						<Grid item>
							<Card elevation={3} sx={{ borderRadius: 3 }}>
								<CardActionArea
									sx={categoryCard}
									onClick={() => handleExpandSection('applications')}
								>
									<AppsIcon color="primary" style={categoryIcon} />
									<Typography variant="h4" component="h2">
										Suites
									</Typography>
									<Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
										Acesse suas aplicações
									</Typography>
								</CardActionArea>
							</Card>
						</Grid>
						{adminModulesCards.length > 0 && (
							<Grid item>
								<Card elevation={3} sx={{ borderRadius: 3 }}>
									<CardActionArea
										sx={categoryCard}
										onClick={() => handleExpandSection('admin')}
									>
										<SettingsIcon color="primary" style={categoryIcon} />
										<Typography variant="h4" component="h2">
											Administração
										</Typography>
										<Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
											Gerencie configurações do sistema
										</Typography>
									</CardActionArea>
								</Card>
							</Grid>
						)}
					</Grid>
				)}
				<Collapse in={expandedSection === 'applications'}>
					<Paper elevation={2} sx={{ mb: 4, borderRadius: 2, overflow: 'hidden' }}>
						<Box sx={sectionTitle}>
							<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
								<AppsIcon color="primary" />
								<Typography variant="h6" component="h2" fontWeight="500">
									Aplicações
								</Typography>
							</Box>
							{hasAdminAccess && hasRegularModuleAccess && (
								<Button
									startIcon={<ArrowBackIcon />}
									onClick={handleCollapseSection}
									size="small"
								>
									Voltar
								</Button>
							)}
						</Box>
						<Box sx={sectionContainer}>
							{accessFlags.masterData && (
								<Box sx={{ width: '100%' }}>
									<Box
										sx={subsectionTitle}
										onClick={() => toggleGroup('masterData')}
									>
										<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
											<StorageIcon color="primary" />
											<Typography variant="subtitle1" fontWeight="500">
												Cadastros
											</Typography>
										</Box>
										{expandedGroups.masterData ? <ExpandLessIcon /> : <ExpandMoreIcon />}
									</Box>
									<Collapse in={expandedGroups.masterData}>
										<Box sx={{
											display: 'flex', flexWrap: 'wrap', gap: '1rem', mb: 2,
										}}
										>
											{createGroupModuleCards(moduleGroups.masterData)}
										</Box>
									</Collapse>
								</Box>
							)}
							{accessFlags.budget && (
								<Box sx={{ width: '100%' }}>
									<Box
										sx={subsectionTitle}
										onClick={() => toggleGroup('budget')}
									>
										<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
											<AccountBalanceWalletIcon color="primary" />
											<Typography variant="subtitle1" fontWeight="500">
												Gestão de Orçamento
											</Typography>
										</Box>
										{expandedGroups.budget ? <ExpandLessIcon /> : <ExpandMoreIcon />}
									</Box>
									<Collapse in={expandedGroups.budget}>
										<Box sx={{
											display: 'flex', flexWrap: 'wrap', gap: '1rem', mb: 2,
										}}
										>
											{createGroupModuleCards(moduleGroups.budget)}
											{(hasPermission({ module: 'BUDGET' }) || hasPermission({ module: 'ORDER' })) && (
												<Card elevation={2} sx={{ borderRadius: 2 }}>
													<CardActionArea component={Link} to="/order/apportionment" sx={actionContent}>
														<CardContent sx={content}>
															<PieChartIcon
																color="primary"
																style={{ fontSize: '3rem' }}
															/>
															<Typography
																variant="subtitle1"
																component="span"
																textAlign="center"
															>
																Gerenciar rateios
															</Typography>
														</CardContent>
													</CardActionArea>
												</Card>
											)}
										</Box>
									</Collapse>
								</Box>
							)}
							{accessFlags.warehouse && (
								<Box sx={{ width: '100%' }}>
									<Box
										sx={subsectionTitle}
										onClick={() => toggleGroup('warehouse')}
									>
										<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
											<WarehouseIcon color="primary" />
											<Typography variant="subtitle1" fontWeight="500">
												Gestão de Armazém
											</Typography>
										</Box>
										{expandedGroups.warehouse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
									</Box>
									<Collapse in={expandedGroups.warehouse}>
										<Box sx={{
											display: 'flex', flexWrap: 'wrap', gap: '1rem', mb: 2,
										}}
										>
											{createGroupModuleCards(moduleGroups.warehouse)}
										</Box>
									</Collapse>
								</Box>
							)}
							{accessFlags.pricing && (
								<Box sx={{ width: '100%' }}>
									<Box
										sx={subsectionTitle}
										onClick={() => toggleGroup('pricing')}
									>
										<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
											<MonetizationOnIcon color="primary" />
											<Typography variant="subtitle1" fontWeight="500">
												Gestão de Preço e Rentabilidade
											</Typography>
										</Box>
										{expandedGroups.pricing ? <ExpandLessIcon /> : <ExpandMoreIcon />}
									</Box>
									<Collapse in={expandedGroups.pricing}>
										<Box sx={{
											display: 'flex', flexWrap: 'wrap', gap: '1rem', mb: 2,
										}}
										>
											{createGroupModuleCards(moduleGroups.pricing)}
										</Box>
									</Collapse>
								</Box>
							)}
							{accessFlags.hours && (
								<Box sx={{ width: '100%' }}>
									<Box
										sx={subsectionTitle}
										onClick={() => toggleGroup('hours')}
									>
										<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
											<AccessTimeIcon color="primary" />
											<Typography variant="subtitle1" fontWeight="500">
												Gestão de Horas
											</Typography>
										</Box>
										{expandedGroups.hours ? <ExpandLessIcon /> : <ExpandMoreIcon />}
									</Box>
									<Collapse in={expandedGroups.hours}>
										<Box sx={{
											display: 'flex', flexWrap: 'wrap', gap: '1rem', mb: 2,
										}}
										>
											{createGroupModuleCards(moduleGroups.hours)}
										</Box>
									</Collapse>
								</Box>
							)}
						</Box>
					</Paper>
				</Collapse>
				<Collapse in={expandedSection === 'admin'}>
					{adminModulesCards.length > 0 && (
						<Paper elevation={2} sx={{ borderRadius: 2, overflow: 'hidden' }}>
							<Box sx={sectionTitle}>
								<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
									<SettingsIcon color="primary" />
									<Typography variant="h6" component="h2" fontWeight="500">
										Administração
									</Typography>
								</Box>
								<Button
									startIcon={<ArrowBackIcon />}
									onClick={handleCollapseSection}
									size="small"
								>
									Voltar
								</Button>
							</Box>
							<Box sx={sectionContainer}>
								{adminModulesCards}
							</Box>
						</Paper>
					)}
				</Collapse>
			</Box>
		</Stack>
	);
};

export default Home;
