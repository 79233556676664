import React, { useCallback, useEffect } from 'react';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Input from '../Common/Form/Input';
import { filterObject } from '../../helpers/Utils';
import Autocomplete from '../Common/Form/Autocomplete';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { IBranch } from '../../containers/Branch/BranchAssets';
import { EpiControlQueryParams } from '../../interfaces/EpiControlQueryParams';

interface IForm {
	name?: string;
	registration?: string;
	admissionStart?: string | Date;
	admissionEnd?: string | Date;
	costCenter?: string;
	position?: string;
	department?: string;
	registrationCompanyId?: string;
	registrationBranchId?: string;
}

interface IFilterProps {
	sendFilter: (values: Partial<EpiControlQueryParams>) => void;
	initialValues: IForm;
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
	getBranches: (companyId: string) => void;
	getCompanies: () => void;
}

const EpiControlFilter = ({
	sendFilter,
	initialValues,
	companies,
	branches,
	getBranches,
	getCompanies,
}:IFilterProps): JSX.Element => {
	const onSubmit = useCallback((submitValues: IForm) => {
		sendFilter(filterObject(submitValues));
	}, [sendFilter]);

	const onReset = useCallback((handleReset) => {
		sendFilter({});
		handleReset();
	}, [sendFilter]);

	const formik = useFormik({
		initialValues,
		onSubmit,
	});

	useEffect(() => {
		getCompanies();
	}, [getCompanies]);

	useEffect(() => {
		if (formik.values.registrationCompanyId) {
			getBranches(formik.values.registrationCompanyId);
		}
	}, [getBranches, formik.values.registrationCompanyId]);

	return (
		<Paper sx={{ p: 2, width: '100%', mb: 2 }}>
			<FormikContext.Provider value={formik}>
				<Form>
					<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>
						<Grid item xs={12}>
							<Input.InputField
								id="registration"
								name="registration"
								label="Matrícula"
								autoComplete="off"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Input.InputField
								id="name"
								name="name"
								label="Nome do Colaborador"
								autoComplete="off"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Input.InputField
								id="department"
								name="department"
								label="Departamento"
								autoComplete="off"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Input.InputField
								id="costCenter"
								name="costCenter"
								label="Centro de Custo"
								autoComplete="off"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Input.InputField
								id="position"
								name="position"
								label="Cargo"
								autoComplete="off"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								label="Empresa de Registro"
								name="registrationCompanyId"
								options={companies}
								labelKey="name"
								valueKey="id"
								valueLabel="code"
							/>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								label="Filial de Registro"
								name="registrationBranchId"
								options={branches}
								labelKey="name"
								valueKey="id"
								valueLabel="code"
							/>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
							<Button variant="outlined" onClick={() => onReset(formik.handleReset)}>
								Restaurar
							</Button>
							<Button variant="contained" type="submit">
								Filtrar
							</Button>
						</Grid>
					</Grid>
				</Form>
			</FormikContext.Provider>
		</Paper>
	);
};

export default EpiControlFilter;
