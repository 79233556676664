import {
	DeliveryHistoryEntry,
	UpdateHistoryEntry,
	HistoryEntry,
	IEmployeeWithEpis,
} from '../containers/EpiControl/EpiControlAssets';
import { ReplacementTimeUnit } from '../enums/ReplacementTimeUnit';

export const getReplacementTimeLabel = (unit?: number): string => {
	if (unit === undefined || unit === null) return 'Tempo Indeterminado';
	if (unit === ReplacementTimeUnit.UNDEFINED) return 'Tempo Indeterminado';

	const units = {
		0: 'dia(s)',
		1: 'mês(es)',
		2: 'ano(s)',
		3: 'Tempo Indeterminado',
	};

	return units[unit as keyof typeof units] || 'Tempo Indeterminado';
};

export const getEpiStatus = (epi: any): { status: string; color: string } => {
	if (!epi.lastReplacement) {
		return { status: 'Pendente', color: 'default' };
	}

	const lastReplacement = new Date(epi.lastReplacement);
	const now = new Date();
	let expiryDate;

	if (epi.replacementTimeUnit === ReplacementTimeUnit.UNDEFINED && epi.caValidity) {
		expiryDate = new Date(lastReplacement);
		expiryDate.setFullYear(expiryDate.getFullYear() + epi.caValidity);
	} else if (epi.replacementTime && epi.replacementTimeUnit !== ReplacementTimeUnit.UNDEFINED) {
		switch (epi.replacementTimeUnit as ReplacementTimeUnit) {
			case 0:
				expiryDate = new Date(lastReplacement);
				expiryDate.setDate(expiryDate.getDate() + epi.replacementTime);
				break;
			case 1:
				expiryDate = new Date(lastReplacement);
				expiryDate.setMonth(expiryDate.getMonth() + epi.replacementTime);
				break;
			case 2:
				expiryDate = new Date(lastReplacement);
				expiryDate.setFullYear(expiryDate.getFullYear() + epi.replacementTime);
				break;
			default:
				return { status: 'Pendente', color: 'default' };
		}
	} else {
		return { status: 'Pendente', color: 'default' };
	}
	const warningDate = new Date(expiryDate);
	warningDate.setDate(warningDate.getDate() - 30);

	if (now > expiryDate) {
		return { status: 'Vencido', color: 'error' };
	} if (now > warningDate) {
		return { status: 'Próx. do Vencimento', color: 'warning' };
	}
	return { status: 'Atualizado', color: 'success' };
};

export const parseDeliveryHistoryEntry = (entry: string): DeliveryHistoryEntry | null => {
	try {
		const cleanEntry = entry.replace(/^\[|\]$/g, '');

		const dateMatch = cleanEntry.match(/Entregue no dia ([0-9/,: ]+) o epi/);

		const epiMatch = cleanEntry.match(/o epi ([^ ]+) - ([^,]+)/);
		const consumptionCodeMatch = cleanEntry.match(/Consumo: ([^ ]+) </);
		const consumptionIdMatch = cleanEntry.match(/<([^>]+)>/);

		if (!dateMatch || !epiMatch || !consumptionIdMatch) {
			return null;
		}

		return {
			type: 'delivery',
			date: dateMatch[1].trim(),
			epiCode: epiMatch[1].trim(),
			epiDescription: epiMatch[2].trim(),
			consumptionCode: consumptionCodeMatch ? consumptionCodeMatch[1].trim() : '',
			consumptionId: consumptionIdMatch[1].trim(),
		};
	} catch (e) {
		return null;
	}
};

export const parseUpdateHistoryEntry = (entry: string): UpdateHistoryEntry | null => {
	try {
		const match = entry.match(/\{(.+?) por: (.+?), na data: (.+?)\}/);
		if (!match) return null;

		const actionString = match[1].toLowerCase();
		const user = match[2].trim();
		const date = match[3].trim();

		let action: 'created' | 'updated' | 'activated' | 'deactivated';

		if (actionString.includes('inativado')) {
			action = 'deactivated';
		} else if (actionString.includes('ativado')) {
			action = 'activated';
		} else if (actionString.includes('criado')) {
			action = 'created';
		} else if (actionString.includes('atualizado')) {
			action = 'updated';
		} else {
			return null;
		}

		return {
			type: 'update',
			action,
			user,
			date,
		};
	} catch (e) {
		return null;
	}
};

export const parseHistoryEntry = (entry: string): HistoryEntry | null => {
	if (entry.includes('[Entregue no dia')) {
		return parseDeliveryHistoryEntry(entry);
	} if (entry.match(/^\{.*\}$/)) {
		return parseUpdateHistoryEntry(entry);
	}
	return null;
};

export const getUpdateActionText = (action: string): string => {
	switch (action) {
		case 'created':
			return 'Criado';
		case 'updated':
			return 'Atualizado';
		case 'activated':
			return 'Ativado';
		case 'deactivated':
			return 'Inativado';
		default:
			return 'Desconhecido';
	}
};

export const handlePrint = (openDetails: IEmployeeWithEpis) => () => {
	const logoUrl = `${window.location.origin}/api/configuration/logo`;
	const originalTitle = document.title;

	if (openDetails) {
		document.title = `Relatório de EPIs - ${openDetails.fullName}`;
	}

	const printContent = document.createElement('div');

	const drawerContent = document.getElementById('drawer-content');
	if (drawerContent) {
		printContent.innerHTML = drawerContent.innerHTML;

		const header = document.createElement('div');
		header.innerHTML = `
			<div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 20px;">
			  <img src="${logoUrl}" alt="Logo da Empresa" style="height: 60px; max-width: 200px; object-fit: contain;" />
			  <h2 style="flex-grow: 1; text-align: center; margin: 0;">Relatório de EPIs do Colaborador</h2>
			  <div style="width: 200px;"></div> <!-- Espaçador para balancear o layout -->
			</div>
			<hr style="margin-bottom: 20px;">
		  `;
		printContent.insertBefore(header, printContent.firstChild);

		const noPrintElements = printContent.querySelectorAll('.no-print, button');
		noPrintElements.forEach((el) => el.remove());

		const accordions = printContent.querySelectorAll('.MuiAccordion-root');
		accordions.forEach((accordion) => {
			const summary = accordion.querySelector('.MuiAccordionSummary-root');
			if (summary) {
				const isExpanded = summary.getAttribute('aria-expanded') === 'true';

				if (!isExpanded) {
					accordion.classList.add('no-print-accordion');
				}
			}
		});

		const style = document.createElement('style');
		style.innerHTML = `
			@media print {
			  body * {
				visibility: hidden;
			  }
			  
			  #print-container, #print-container * {
				visibility: visible;
			  }
			
			  .no-print-accordion {
        		display: none !important;
      		  }
			  
			  #print-container {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				padding: 20px;
				box-sizing: border-box;
			  }
			  
			  /* Estilos para garantir que tudo seja impresso corretamente */
			  table {
				width: 100%;
				border-collapse: collapse;
			  }
			  
			  th, td {
				border: 1px solid #ddd;
				padding: 8px;
				text-align: left;
			  }
			  
			  /* Força a impressão de cores */
			  * {
				print-color-adjust: exact !important;
				-webkit-print-color-adjust: exact !important;
			  }
			  
			  /* Estilos para o cabeçalho do relatório */
			  img {
				-webkit-print-color-adjust: exact !important;
				print-color-adjust: exact !important;
			  }
			}
		  `;

		printContent.id = 'print-container';
		document.body.appendChild(style);
		document.body.appendChild(printContent);

		setTimeout(() => {
			window.print();

			document.title = originalTitle;

			document.body.removeChild(printContent);
			document.body.removeChild(style);
		}, 200);
	}
};
