import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Box } from '@mui/material';
import { Loading } from '../Common/Loading';
import { IEmployeeWithEpi, UpsertEmployeeEpi } from '../../containers/EpiControl/EpiControlAssets';
import { ProductEPIQueryParams } from '../../interfaces/ProductEpiQueryParams';
import { IProductEPI } from '../../containers/Product/ProductAssets';
import EpiControlForm from './EpiControlForm';
import { PageHeader } from '../Common/PageHeader/PageHeader';

interface EpiControlEditProps {
	loading: boolean;
	employeeWithEpi?: IEmployeeWithEpi;
	epiProducts?: IProductEPI[];
	getEmployeeWithEpiById: (employeeId: string, active?: boolean) => void;
	handleDelete: (id: string) => void;
	handleActive: (id: string, active: boolean) => Promise<any>;
	upsertEmployeeEpi(employeeId: string, data: UpsertEmployeeEpi): void;
	getEPIProducts(queryParams?: ProductEPIQueryParams): void;
}

const EpiControlEdit = ({
	loading,
	employeeWithEpi,
	epiProducts,
	getEmployeeWithEpiById,
	handleDelete,
	handleActive,
	upsertEmployeeEpi,
	getEPIProducts,
}: EpiControlEditProps): JSX.Element => {
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			getEmployeeWithEpiById(id);
			getEPIProducts();
		}
	}, [id, getEmployeeWithEpiById, getEPIProducts]);

	const handleSubmit = useCallback((values: any) => {
		if (id && employeeWithEpi?.employeeId) {
			upsertEmployeeEpi(employeeWithEpi.employeeId, values);
		}
	}, [id, employeeWithEpi, upsertEmployeeEpi]);

	const pageHeaderMemo = useMemo(() => (
		<PageHeader
			title="Gerenciar EPIs do Colaborador"
			icon={<EngineeringIcon fontSize="large" color="primary" />}
		/>
	), []);

	if (id && !employeeWithEpi) {
		return <Loading />;
	}

	return (
		<Box className="content">
			{pageHeaderMemo}
			<EpiControlForm
				loading={loading}
				employeeWithEpi={employeeWithEpi}
				epiProducts={epiProducts}
				getEmployeeWithEpiById={getEmployeeWithEpiById}
				handleDelete={handleDelete}
				handleActive={handleActive}
				onSubmit={handleSubmit}
			/>
		</Box>
	);
};

EpiControlEdit.defaultProps = {
	employeeWithEpi: undefined,
	epiProducts: undefined,
};

export default EpiControlEdit;
