import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import EpiControlPresentational from '../../components/EpiControl/EpiControl';
import { getAllEmployeesWithEpis } from '../../services/epiControl';
import { IEmployeeWithEpis } from './EpiControlAssets';
import { EpiControlQueryParams } from '../../interfaces/EpiControlQueryParams';
import { ICompanyWithoutDetails } from '../Company/CompanyAssets';
import { IBranch } from '../Branch/BranchAssets';
import { getCompanies } from '../../services/company';
import { getBranches } from '../../services/branch';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import { getLocations } from '../../services/location';
import { getEPIProducts } from '../../services/product';
import { ProductEPIQueryParams } from '../../interfaces/ProductEpiQueryParams';
import { CreateConsumptionTasksParams } from '../Consumption/ConsumptionAssets';
import { createConsumptionTasks, getConsumptionSignature } from '../../services/consumption';
import { ILocation } from '../Location/LocationAssets';
import { IProductEPI } from '../Product/ProductAssets';

  enum ActionType {
	LOADING,
	EMPLOYEES_WITH_EPIS,
	COMPANY,
	BRANCH,
	LOCATION,
	EPI_PRODUCTS,
	SIGNATURE,
  }

  interface IState {
	loading: boolean;
	employeesWithEpis: IEmployeeWithEpis[];
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
	locations: ILocation[];
	epiProducts: IProductEPI[];
	signature: string | null;
	page: number;
	take: number;
	pages: number;
  }

  type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.COMPANY; payload: { companies: ICompanyWithoutDetails[] } }
	| { type: ActionType.BRANCH; payload: { branches: IBranch[] } }
	| { type: ActionType.LOCATION; payload: { locations: ILocation[] } }
	| { type: ActionType.EPI_PRODUCTS; payload: { epiProducts: IProductEPI[] } }
	| { type: ActionType.EMPLOYEES_WITH_EPIS; payload: {
		employeesWithEpis: IEmployeeWithEpis[];
		pages: number;
		page: number;
		take: number;
	} }
	| { type: ActionType.SIGNATURE; payload: { signature: string | null } };

  interface IEpiControlActions {
	setLoading(loading: boolean): void;
	createConsumptionTasks: (data: CreateConsumptionTasksParams) => void;
	getAllEmployeesWithEpis: (params: EpiControlQueryParams) => void;
	getCompanies(): void;
	getBranches(companyId: string): void;
	getLocations: (queryParams?: LocationProductQueryParams) => void;
	getEPIProducts: (queryParams?: ProductEPIQueryParams) => void;
	getConsumptionSignature: (consumptionId: string) => void;
	handleEdit(id: string): void;
  }

let lastQueryParams: EpiControlQueryParams;

const initialState: IState = {
	loading: false,
	employeesWithEpis: [],
	companies: [],
	branches: [],
	locations: [],
	epiProducts: [],
	page: 0,
	take: 10,
	pages: 0,
	signature: null,
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.COMPANY:
			return {
				...state,
				companies: action.payload.companies,
			};
		case ActionType.BRANCH:
			return {
				...state,
				branches: action.payload.branches,
			};
		case ActionType.LOCATION:
			return {
				...state,
				locations: action.payload.locations,
			};
		case ActionType.EPI_PRODUCTS:
			return {
				...state,
				epiProducts: action.payload.epiProducts,
			};
		case ActionType.EMPLOYEES_WITH_EPIS:
			return {
				...state,
				employeesWithEpis: action.payload.employeesWithEpis,
				pages: action.payload.pages,
				page: action.payload.page,
				take: action.payload.take,
			};
		case ActionType.SIGNATURE:
			return {
				...state,
				signature: action.payload.signature,
			};
		default:
			throw new Error('Ação não reconhecida');
	}
};

const EpiControlActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (
	message: SnackbarMessage,
	options?: OptionsObject | undefined
	) => SnackbarKey,
	navigate: NavigateFunction,
): IEpiControlActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getCompanies() {
			getCompanies().then((response) => {
				dispatch({
					type: ActionType.COMPANY,
					payload: {
						companies: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao carregar empresas.', {
					variant: 'error',
				});
			});
		},
		getBranches(companyId: string) {
			getBranches({ companyId }).then((response) => {
				dispatch({
					type: ActionType.BRANCH,
					payload: {
						branches: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao carregar filiais.', {
					variant: 'error',
				});
			});
		},
		getAllEmployeesWithEpis(params: EpiControlQueryParams) {
			actions.setLoading(true);

			const skip = params.skip ?? 0;
			const take = params.take ?? 10;

			const queryParams = {
				...lastQueryParams,
				skip: skip * take,
				take,
				registration: params.registration,
				name: params.name,
				costCenter: params.costCenter,
				position: params.position,
				department: params.department,
				registrationCompanyId: params.registrationCompanyId,
				registrationBranchId: params.registrationBranchId,
			};

			lastQueryParams = queryParams;

			getAllEmployeesWithEpis(queryParams)
				.then((response) => {
					dispatch({
						type: ActionType.EMPLOYEES_WITH_EPIS,
						payload: {
							employeesWithEpis: response.data.data,
							pages: Math.ceil(response.data.count / take),
							page: params.skip,
							take,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao obter dados dos colaboradores e seus EPIs',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		handleEdit(id: string) {
			navigate(`/edit/${id}`);
		},
		getLocations(queryParams?: LocationProductQueryParams) {
			getLocations(queryParams)
				.then((response) => {
					dispatch({
						type: ActionType.LOCATION,
						payload: {
							locations: response.data.locations,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				});
		},
		getEPIProducts(queryParams?: ProductEPIQueryParams) {
			getEPIProducts(queryParams)
				.then((response) => {
					dispatch({
						type: ActionType.EPI_PRODUCTS,
						payload: { epiProducts: response.data.data },
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao obter produtos EPI',
						{ variant: 'error' },
					);
				});
		},
		createConsumptionTasks(data: CreateConsumptionTasksParams) {
			createConsumptionTasks(data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Tarefas criadas com sucesso!',
						{ variant: 'success' },
					);
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao criar tarefas',
						{
							variant: 'error',
						},
					);
				});
		},
		getConsumptionSignature(consumptionId: string) {
			getConsumptionSignature(consumptionId)
				.then((response) => {
					dispatch({
						type: ActionType.SIGNATURE,
						payload: {
							signature: response.data.signature,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao obter assinatura do consumo',
						{ variant: 'error' },
					);
				});
		},
	};

	return actions;
};

const EpiControl = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => EpiControlActions(dispatch, enqueueSnackbar, navigate),
		[dispatch, enqueueSnackbar, navigate],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <EpiControlPresentational {...state} {...actions} />;
};

export default EpiControl;
