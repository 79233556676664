import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';

export const employeeColumns: GridColDef[] = [
	{
		field: 'registration',
		headerName: 'Matrícula',
		flex: 1,
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
	{
		field: 'fullName',
		headerName: 'Nome Completo',
		flex: 1,
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
	{
		field: 'birthDate',
		headerName: 'Data de Nascimento',
		flex: 1,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={(format(new Date(params.value), 'dd/MM/yyyy')) || '-'} />
		),
	},
	{
		field: 'admissionDate',
		headerName: 'Data de Admissão',
		flex: 1,
		renderCell: (params: GridRenderCellParams) => (
			<TruncateTooltipCell value={(format(new Date(params.value), 'dd/MM/yyyy')) || '-'} />
		),
	},
	{
		field: 'department',
		headerName: 'Departamento',
		flex: 1,
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
	{
		field: 'costCenter',
		headerName: 'Centro de Custo',
		flex: 1,
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
	{
		field: 'position',
		headerName: 'Cargo',
		flex: 1,
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
	{
		field: 'registrationCompanyName',
		headerName: 'Empresa de Registro',
		flex: 1,
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
	{
		field: 'registrationBranchName',
		headerName: 'Filial de Registro',
		flex: 1,
		renderCell: (params: GridRenderCellParams<string>) => (
			<TruncateTooltipCell value={params.value} />
		),
	},
];
