import React, { useCallback, useMemo, useState } from 'react';
import {
	Box,
	Grid,
	Paper,
	Typography,
	IconButton,
	Divider,
	Stack,
	Avatar,
	Tooltip,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Alert,
} from '@mui/material';
import {
	Add as AddIcon,
	Delete as DeleteIcon,
	Person as PersonIcon,
	HealthAndSafety as HealthAndSafetyIcon,
	Save as SaveIcon,
	Edit as EditIcon,
	Close as CloseIcon,
	ToggleOff as ToggleOffIcon,
	ToggleOn as ToggleOnIcon,
	Visibility as VisibilityIcon,
	VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { Formik, Form } from 'formik';
import Input from '../Common/Form/Input';
import Autocomplete from '../Common/Form/Autocomplete';
import {
	IEmployeeWithEpi,
	IEmployeeEpi,
	initialAddEpiState,
	addEpiValidationSchema,
} from '../../containers/EpiControl/EpiControlAssets';
import { IProductEPI } from '../../containers/Product/ProductAssets';
import Button from '../Common/Button/Button';

interface EpiControlFormProps {
  employeeWithEpi?: IEmployeeWithEpi;
  epiProducts?: IProductEPI[];
  loading: boolean;
  getEmployeeWithEpiById: (employeeId: string, active?: boolean) => void;
  handleDelete?: (id: string) => void;
  handleActive: (id: string, active: boolean) => Promise<any>;
  onSubmit: (values: any) => void;
}

const EpiControlForm = ({
	employeeWithEpi,
	epiProducts,
	loading,
	getEmployeeWithEpiById,
	handleDelete,
	handleActive,
	onSubmit,
}: EpiControlFormProps): JSX.Element => {
	const [editingEpiId, setEditingEpiId] = useState<string | null>(null);
	const [editQuantity, setEditQuantity] = useState<number>(1);
	const [editNotes, setEditNotes] = useState<string>('');
	const [showingInactive, setShowingInactive] = useState(false);

	const handleStartEdit = useCallback((epi: IEmployeeEpi): void => {
		setEditingEpiId(epi.id);
		setEditQuantity(epi.quantity);
		setEditNotes(epi.notes || '');
	}, []);

	const handleSaveEdit = useCallback(() => {
		if (employeeWithEpi && editingEpiId) {
			const epi = employeeWithEpi.epis.find((e) => e.id === editingEpiId);
			if (epi) {
				const formattedData = {
					epis: [{
						id: editingEpiId,
						productId: epi.productId,
						quantity: editQuantity,
						notes: editNotes,
					}],
				};
				onSubmit(formattedData);
				setEditingEpiId(null);
			}
		}
	}, [employeeWithEpi, editingEpiId, editQuantity, editNotes, onSubmit]);

	const handleCancelEdit = useCallback(() => {
		setEditingEpiId(null);
	}, []);

	const handleAddEpi = useCallback((values: any, { resetForm }: any): void => {
		if (employeeWithEpi) {
			const formattedData = {
				epis: [{
					productId: values.productId,
					quantity: values.quantity || 1,
					notes: values.notes,
				}],
			};
			onSubmit(formattedData);
			resetForm();
		}
	}, [employeeWithEpi, onSubmit]);

	const toggleInactiveVisibility = useCallback(() => {
		const newState = !showingInactive;
		setShowingInactive(newState);

		if (employeeWithEpi?.employeeId) {
			if (newState) {
				getEmployeeWithEpiById(employeeWithEpi.employeeId, false);
			} else {
				getEmployeeWithEpiById(employeeWithEpi.employeeId);
			}
		}
	}, [showingInactive, employeeWithEpi, getEmployeeWithEpiById]);

	const handleToggleActive = useCallback((epi: IEmployeeEpi) => {
		const newActiveState = !epi.active;
		Promise.resolve(handleActive(epi.id, newActiveState))
			.then(() => {
				if (newActiveState && showingInactive) {
					toggleInactiveVisibility();
				}
				if (!newActiveState && !showingInactive) {
					toggleInactiveVisibility();
				}
			});
	}, [handleActive, showingInactive, toggleInactiveVisibility]);

	const availableProducts = useMemo(() => {
		if (!epiProducts || !employeeWithEpi?.epis?.length) {
			return epiProducts || [];
		}
		const associatedProductIds = employeeWithEpi.epis.map((epi) => epi.productId);
		return epiProducts.filter((product) => !associatedProductIds.includes(product.id));
	}, [epiProducts, employeeWithEpi?.epis]);

	return (
		<Paper sx={{ mb: 3, p: 3, overflow: 'hidden' }} elevation={3}>
			<Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 3 }}>
				<Avatar sx={{ bgcolor: 'primary.main' }}>
					<PersonIcon />
				</Avatar>
				<Typography variant="h5" component="div">
					Dados do Colaborador
				</Typography>
			</Stack>
			<Divider sx={{ mb: 3 }} />

			<Grid container spacing={2} sx={{ mb: 3 }}>
				<Grid item xs={3}>
					<TextField
						id="employeeRegistration"
						label="Matrícula"
						value={employeeWithEpi?.employeeRegistration || ''}
						InputProps={{ readOnly: true }}
						fullWidth
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={9}>
					<TextField
						id="employeeFullName"
						label="Nome Completo"
						value={employeeWithEpi?.employeeFullName || ''}
						InputProps={{ readOnly: true }}
						fullWidth
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						id="employeeDepartment"
						label="Departamento"
						value={employeeWithEpi?.employeeDepartment || ''}
						InputProps={{ readOnly: true }}
						fullWidth
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						id="employeePosition"
						label="Cargo"
						value={employeeWithEpi?.employeePosition || ''}
						InputProps={{ readOnly: true }}
						fullWidth
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						id="employeeRegistrationBranchName"
						label="Filial de Registro"
						value={employeeWithEpi?.employeeRegistrationBranchName || ''}
						InputProps={{ readOnly: true }}
						fullWidth
						variant="outlined"
					/>
				</Grid>
			</Grid>
			<Stack
				direction="row"
				spacing={2}
				alignItems="center"
				sx={{ mb: 3, mt: 4, justifyContent: 'space-between' }}
			>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Avatar sx={{ bgcolor: 'secondary.main', mr: 2 }}>
						<HealthAndSafetyIcon />
					</Avatar>
					<Typography variant="h5" component="div">
						EPIs Associados
					</Typography>
				</Box>
				<Tooltip title={showingInactive ? 'Visualizar EPIs ativos' : 'Visualizar EPIs desativados'}>
					<IconButton
						onClick={toggleInactiveVisibility}
						color={showingInactive ? 'default' : 'primary'}
					>
						{showingInactive ? <VisibilityOffIcon /> : <VisibilityIcon />}
					</IconButton>
				</Tooltip>
			</Stack>
			{showingInactive && (
				<Alert
					severity="warning"
					variant="standard"
					icon={<VisibilityOffIcon />}
					sx={{ mt: 2, mb: 2 }}
				>
					Você está visualizando os EPIs
					{' '}
					<strong>desativados</strong>
					{' '}
					deste colaborador.
				</Alert>
			)}
			<Divider sx={{ mb: 3 }} />
			{employeeWithEpi?.epis && employeeWithEpi.epis.length > 0 ? (
				<TableContainer component={Paper} variant="outlined" sx={{ mb: 2 }}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell width="40%">Produto</TableCell>
								<TableCell width="10%" align="center">UM</TableCell>
								<TableCell width="10%" align="center">Qtd.</TableCell>
								<TableCell width="32%">Observações</TableCell>
								<TableCell width="8%" align="center" sx={{ pr: 1 }}>Ações</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{employeeWithEpi.epis.map((epi: IEmployeeEpi) => (
								<TableRow key={epi.id} hover>
									<TableCell>
										{epi.productCode}
										{' '}
										-
										{' '}
										{epi.productDescription}
									</TableCell>
									<TableCell align="center">
										{epi.primaryMeasureDescription}
									</TableCell>
									<TableCell align="center">
										{(() => {
											if (editingEpiId === epi.id) {
												return (
													<TextField
														size="small"
														type="number"
														value={editQuantity}
														onChange={(e) => setEditQuantity(Number(e.target.value))}
														inputProps={{ min: 1, style: { textAlign: 'center' } }}
														variant="outlined"
														sx={{ width: '60px' }}
														autoFocus
													/>
												);
											}
											if (showingInactive) {
												return <Box>{epi.quantity}</Box>;
											}
											return (
												<Tooltip title="Clique para editar">
													<Box
														onClick={() => handleStartEdit(epi)}
														sx={{
															cursor: 'pointer',
															'&:hover': {
																textDecoration: 'underline',
																color: 'primary.main',
															},
														}}
													>
														{epi.quantity}
													</Box>
												</Tooltip>
											);
										})()}
									</TableCell>
									<TableCell sx={{ pr: 0, wordBreak: 'break-word' }}>
										{(() => {
											if (editingEpiId === epi.id) {
												return (
													<TextField
														size="small"
														multiline
														rows={1}
														fullWidth
														value={editNotes}
														onChange={(e) => setEditNotes(e.target.value)}
														variant="outlined"
													/>
												);
											}
											if (showingInactive) {
												return <Box>{epi.notes || '-'}</Box>;
											}
											return (
												<Tooltip title="Clique para editar">
													<Box
														onClick={() => handleStartEdit(epi)}
														sx={{
															cursor: 'pointer',
															'&:hover': {
																textDecoration: 'underline',
																color: 'primary.main',
															},
														}}
													>
														{epi.notes || '-'}
													</Box>
												</Tooltip>
											);
										})()}
									</TableCell>
									<TableCell align="center">
										<Box sx={{ display: 'flex', justifyContent: 'center' }}>
											{editingEpiId === epi.id ? (
												<>
													<Tooltip title="Salvar alterações">
														<IconButton
															color="primary"
															size="small"
															onClick={handleSaveEdit}
														>
															<SaveIcon fontSize="small" />
														</IconButton>
													</Tooltip>
													<Tooltip title="Cancelar">
														<IconButton
															color="error"
															size="small"
															onClick={handleCancelEdit}
														>
															<CloseIcon fontSize="small" />
														</IconButton>
													</Tooltip>
												</>
											) : (
												<>
													<Tooltip title="Editar EPI">
														<IconButton
															color="primary"
															size="small"
															disabled={showingInactive}
															onClick={() => handleStartEdit(epi)}
														>
															<EditIcon fontSize="small" />
														</IconButton>
													</Tooltip>
													<Tooltip title={epi.active ? 'Desativar EPI' : 'Ativar EPI'}>
														<IconButton
															color={epi.active ? 'primary' : 'secondary'}
															size="small"
															onClick={() => handleToggleActive(epi)}
														>
															{epi.active
																? <ToggleOffIcon fontSize="small" />
																: <ToggleOnIcon fontSize="small" />}
														</IconButton>
													</Tooltip>
													{handleDelete && (
														<Tooltip title="Remover EPI">
															<IconButton
																color="error"
																size="small"
																disabled={showingInactive}
																onClick={() => handleDelete(epi.id)}
															>
																<DeleteIcon fontSize="small" />
															</IconButton>
														</Tooltip>
													)}
												</>
											)}
										</Box>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<Box sx={{
					my: 3,
					p: 3,
					textAlign: 'center',
					bgcolor: 'background.paper',
					borderRadius: 1,
					border: '1px dashed',
					borderColor: 'divider',
				}}
				>
					<HealthAndSafetyIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 1 }} />
					<Typography color="textSecondary" variant="h6" sx={{ mb: 1 }}>
						Nenhum EPI associado
					</Typography>
					<Typography color="textSecondary" variant="body2">
						Utilize o formulário abaixo para adicionar EPIs a este colaborador.
					</Typography>
				</Box>
			)}
			<Box
				sx={{
					mt: 2,
					p: 2,
					border: '1px solid',
					borderColor: 'divider',
					borderRadius: 1,
					bgcolor: 'background.paper',
				}}
			>
				<Formik
					initialValues={initialAddEpiState}
					validationSchema={addEpiValidationSchema}
					onSubmit={handleAddEpi}
				>
					{({ values, isSubmitting }) => (
						<Form>
							<Grid container spacing={2} alignItems="flex-end">
								<Grid item xs={5} sm={5} md={4}>
									<Autocomplete
										options={availableProducts}
										labelKey="description"
										valueKey="id"
										valueLabel="code"
										label="Selecione o produto EPI"
										name="productId"
										required
									/>
								</Grid>
								<Grid item xs={2} sm={1.5} md={1}>
									<Input.InputField
										id="quantity"
										name="quantity"
										label="Quantidade"
										type="number"
										fullWidth
									/>
								</Grid>
								<Grid item xs={3} sm={4} md={5.5}>
									<Input.InputField
										id="notes"
										name="notes"
										label="Observações"
										multiline
										rows={1}
										fullWidth
									/>
								</Grid>
								<Grid item xs={2} sm={1.5} md={1.5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Button
										variant="contained"
										color="primary"
										type="submit"
										startIcon={<AddIcon />}
										size="medium"
										disabled={!values.productId
											|| loading || isSubmitting || availableProducts.length === 0}
										onClick={() => { setShowingInactive(false); }}
										sx={{
											height: '56px',
											width: '100%',
											minWidth: { xs: '36px', sm: '100%' },
											px: { xs: 0, sm: 1, md: 2 },
											'& .MuiButton-startIcon': {
												m: { xs: '0 auto', sm: 'auto 4px auto 0' },
											},
										}}
									>
										<Box sx={{
											display: { xs: 'none', sm: 'block' },
											fontSize: { sm: '0.875rem', md: '0.875rem' },
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
										>
											Adicionar
										</Box>
									</Button>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</Box>
		</Paper>
	);
};

EpiControlForm.defaultProps = {
	employeeWithEpi: undefined,
	epiProducts: [],
	handleDelete: undefined,
};

export default EpiControlForm;
