import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import EngineeringIcon from '@mui/icons-material/Engineering';
import FilterListIcon from '@mui/icons-material/FilterList';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import DrawerFilter from '../Common/DrawerFilter';
import { EpiControlQueryParams } from '../../interfaces/EpiControlQueryParams';
import useColumns from '../../hooks/useColumns';
import { useManageColumns } from '../../hooks/useManageColumns';
import { ToolbarComponent } from '../Common/Datagrid/DataGridToolBar';
import {
	IEmployeeWithEpis,
} from '../../containers/EpiControl/EpiControlAssets';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import { epiControlColumns } from '../../constants/epiControlColumns';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { IBranch } from '../../containers/Branch/BranchAssets';
import EpiControlFilter from './EpiControlFilter';
import DetailsDrawer from './Drawers/DetailsDrawer';
import { ILocation } from '../../containers/Location/LocationAssets';
import { IProductEPI } from '../../containers/Product/ProductAssets';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import { ProductEPIQueryParams } from '../../interfaces/ProductEpiQueryParams';
import ConsumptionEPIDrawer from './Drawers/ConsumptionEPIDrawer';
import { CreateConsumptionTasksParams } from '../../containers/Consumption/ConsumptionAssets';

const localStorageKey = 'epiControl';

const columnsVisibility = [
	'registration',
	'fullName',
	'department',
	'position',
	'registrationCompanyName',
	'registrationBranchName',
	'epiCount',
	'lastReplacementEpi',
	'actions',
];

const optionalColumnsVisibility = [
	'admissionDate',
	'costCenter',
];

const initialSortModel: GridSortModel = [
	{ field: 'fullName', sort: 'asc' },
];

  interface EpiControlProps {
	loading: boolean;
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
	locations: ILocation[];
	epiProducts: IProductEPI[];
	employeesWithEpis: IEmployeeWithEpis[];
	signature: string | null;
	pages: number;
	page: number;
	take: number;
	getCompanies: () => void;
	getBranches: (companyId: string) => void;
	getLocations: (queryParams?: LocationProductQueryParams) => void;
	getEPIProducts: (queryParams?: ProductEPIQueryParams) => void;
	getAllEmployeesWithEpis: (params: EpiControlQueryParams) => void;
	getConsumptionSignature: (consumptionId: string) => void;
	handleEdit: (id: string) => void;
	createConsumptionTasks: (data: CreateConsumptionTasksParams) => void;
  }

const EpiControl = ({
	loading,
	companies,
	branches,
	locations,
	epiProducts,
	employeesWithEpis,
	signature,
	pages,
	page,
	take,
	getCompanies,
	getBranches,
	getLocations,
	getEPIProducts,
	getAllEmployeesWithEpis,
	getConsumptionSignature,
	handleEdit,
	createConsumptionTasks,
}: EpiControlProps): JSX.Element => {
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [openDetails, setOpenDetails] = useState<IEmployeeWithEpis | undefined>(undefined);
	const [openConsumption, setOpenConsumption] = useState<IEmployeeWithEpis | undefined>(undefined);

	const {
		filter,
		setFilter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<EpiControlQueryParams>({
		initialSortModel,
		initialPageSize: take,
		initialPage: page,
		initialFilterValues: { skip: 0 },
		fetchData: getAllEmployeesWithEpis,
	});

	const sendFilter = useCallback((values: Partial<EpiControlQueryParams>) => {
		const pageFilter = {
			...values,
			skip: 0,
		};
		setFilter(pageFilter);
	}, [setFilter]);

	const onDetailsClick = useCallback((id: string) => {
		const selectedEmployee = employeesWithEpis.find(
			(employeesWithEpi) => employeesWithEpi.id === id,
		);
		setOpenDetails(selectedEmployee);
	}, [employeesWithEpis]);

	const onCloseDetails = useCallback(() => {
		setOpenDetails(undefined);
	}, []);

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen((prevOpen) => !prevOpen);
	}, []);

	const handleFilterSubmit = useCallback((
		newFilterValues: Partial<EpiControlQueryParams>,
	) => {
		setFilterDrawerOpen(false);
		sendFilter(newFilterValues);
	}, [sendFilter]);

	const countActiveFilters = useCallback((): number => Object.entries(filter)
		.filter(([key, value]) => {
			const excludedKeys = ['skip', 'take', 'orderBy'];
			if (excludedKeys.includes(key)) {
				return false;
			}
			return value !== undefined;
		})
		.length, [filter]);

	const handleConsumptionClick = useCallback((id: string) => {
		const selectedEmployee = employeesWithEpis.find(
			(employee) => employee.id === id,
		);
		setOpenConsumption(selectedEmployee);
	}, [employeesWithEpis]);

	const { columns, defaultVisibility } = useColumns({
		columnsVisibility,
		defaultColumnsOverride: epiControlColumns,
		optionalColumnsVisibility,
		onEditClick: handleEdit,
		editIcon: <HealthAndSafetyIcon sx={{ fontSize: '1.6rem' }} />,
		editTooltip: 'Gerenciar EPI',
		onDetailsClick,
		onConsumptionClick: handleConsumptionClick,
	});

	const {
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
	} = useManageColumns({
		initialColumns: columns,
		initialVisibility: defaultVisibility,
		localStorageKey,
		isDraggable: true,
	});

	const headerButtonsProps = useMemo((): PageHeaderButtonProps[] => [
		{
			variant: 'contained',
			color: 'primary',
			onClick: toggleFilterDrawer,
			badgeContent: countActiveFilters(),
			text: 'Filtro',
			startIcon: <FilterListIcon />,
		},
	], [countActiveFilters, toggleFilterDrawer]);

	const pageHeaderMemo = useMemo(() => <PageHeader title="Controle de EPI" icon={<EngineeringIcon fontSize="large" color="primary" />} buttons={headerButtonsProps} />, [headerButtonsProps]);

	const filterMemo = useMemo(() => (
		<DrawerFilter open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
			<EpiControlFilter
				sendFilter={handleFilterSubmit}
				initialValues={filter}
				companies={companies}
				branches={branches}
				getCompanies={getCompanies}
				getBranches={getBranches}
			/>
		</DrawerFilter>
	), [
		branches,
		companies,
		filter,
		getBranches,
		getCompanies,
		handleFilterSubmit,
		isFilterDrawerOpen,
		toggleFilterDrawer,
	]);

	const detailsDrawerMemo = useMemo(() => (
		<DetailsDrawer
			openDetails={openDetails}
			onClose={onCloseDetails}
			getConsumptionSignature={getConsumptionSignature}
			signature={signature}
		/>
	), [openDetails, onCloseDetails, getConsumptionSignature, signature]);

	const consumptionEPIDrawerMemo = useMemo(
		() => (
			<ConsumptionEPIDrawer
				open={Boolean(openConsumption)}
				onClose={() => { setOpenConsumption(undefined); }}
				employee={openConsumption}
				locations={locations}
				getLocations={getLocations}
				epiProducts={epiProducts}
				getEPIProducts={getEPIProducts}
				createConsumptionTasks={createConsumptionTasks}
			/>
		),
		[
			createConsumptionTasks,
			getLocations,
			getEPIProducts,
			locations,
			epiProducts,
			openConsumption,
		],
	);

	return (
		<Box className="content">
			{pageHeaderMemo}
			{filterMemo}
			<Box component={Paper} sx={{ width: '100%', mt: 2 }}>
				<DataGrid
					autoHeight
					rows={employeesWithEpis}
					rowCount={pages * take}
					pagination
					paginationMode="server"
					sortingMode="server"
					columns={currentColumns}
					page={page}
					pageSize={take}
					rowsPerPageOptions={[10, 25, 50, 100]}
					sortModel={sortModel}
					onPageChange={onChangePage}
					onPageSizeChange={onChangePageSize}
					onSortModelChange={onSortModelChange}
					columnVisibilityModel={columnVisibilityModel}
					onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
					components={{ Toolbar: ToolbarComponent(onRestoreClick) }}
					disableSelectionOnClick
					loading={loading}
					experimentalFeatures={{ newEditingApi: true }}
				/>
			</Box>
			{detailsDrawerMemo}
			{consumptionEPIDrawerMemo}
		</Box>
	);
};

export default EpiControl;
