import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import EmployeeEditPresentational from '../../components/Employee/EmployeeEdit';
import { IEmployee } from './EmployeeAssets';
import { createEmployee, getEmployeeById, updateEmployee } from '../../services/employee';
import { ICompanyWithoutDetails } from '../Company/CompanyAssets';
import { IBranch } from '../Branch/BranchAssets';
import { getUserBranchesByCompany, getUserCompanies } from '../../services/user';

enum ActionType {
    LOADING,
	EMPLOYEE,
	COMPANY,
	BRANCH
}

interface IState {
    loading: boolean;
	employee?: IEmployee;
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
}

type TAction =
    | { type: ActionType.LOADING; payload: { loading: boolean } }
    | { type: ActionType.EMPLOYEE; payload: { employee: IEmployee } }
    | { type: ActionType.COMPANY; payload: { companies: ICompanyWithoutDetails[] } }
    | { type: ActionType.BRANCH; payload: { branches: IBranch[] } }

interface IEmployeeActions {
    setLoading(loading: boolean): void;
	getEmployeeById(id: string): void;
	setEmployee(employee: IEmployee): void;
	createEmployee(data: IEmployee): void;
	updateEmployee(id: string, data: Partial<IEmployee>): void;
	getCompanies(): void;
	getBranches: (companyId: string) => void;
}

const initialState: IState = {
	loading: false,
	companies: [],
	branches: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.EMPLOYEE:
			return { ...state, employee: action.payload.employee };
		case ActionType.COMPANY:
			return { ...state, companies: action.payload.companies };
		case ActionType.BRANCH:
			return { ...state, branches: action.payload.branches };
		default:
			throw new Error();
	}
};

const EmployeeEditActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IEmployeeActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setEmployee(employee: IEmployee) {
			dispatch({ type: ActionType.EMPLOYEE, payload: { employee } });
		},
		getEmployeeById(id: string) {
			actions.setLoading(true);
			getEmployeeById(id)
				.then((response) => {
					if (!response.data) {
						enqueueSnackbar('Colaborador não encontrado.', {
							variant: 'error',
						});
						navigate('/employee');
					}
					actions.setEmployee(response.data);
				})
				.catch((error) => {
					enqueueSnackbar(error.response?.data.message || 'Erro ao obter colaborador', {
						variant: 'error',
					});
				}).finally(() => actions.setLoading(false));
		},
		createEmployee(data: IEmployee) {
			createEmployee(data).then((response) => {
				enqueueSnackbar(response?.data.message || 'Colaborador excluído com sucesso!', { variant: 'success' });
				navigate('/employee');
			})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
		},
		updateEmployee(id: string, data: Partial<IEmployee>) {
			updateEmployee(id, data).then((response) => {
				enqueueSnackbar(response?.data.message || 'Colaborador excluído com sucesso!', { variant: 'success' });
				navigate('/employee');
			})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
		},
		getCompanies() {
			actions.setLoading(true);
			getUserCompanies().then((response) => {
				dispatch({
					type: ActionType.COMPANY,
					payload: {
						companies: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao carregar empresas.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		getBranches(companyId: string) {
			actions.setLoading(true);
			getUserBranchesByCompany(companyId).then((response) => {
				dispatch({
					type: ActionType.BRANCH,
					payload: {
						branches: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao carregar filiais.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
	};

	return actions;
};

const EmployeeEdit = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => EmployeeEditActions(dispatch, enqueueSnackbar, navigate),
		[dispatch, enqueueSnackbar, navigate],
	);
	return (
	/* eslint-disable react/jsx-props-no-spreading */
		<EmployeeEditPresentational {...state} {...actions} />
	);
};

export default EmployeeEdit;
