import React, { useCallback, useEffect, useState } from 'react';
import {
	Drawer,
	Box,
	Typography,
	Grid,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
	FormControl,
	Card,
	CardHeader,
	CardContent,
	Avatar,
	Divider,
} from '@mui/material';
import { Form, FormikContext, useFormik } from 'formik';
import {
	Check,
	Delete,
	AddModerator,
} from '@mui/icons-material';
import Autocomplete from '../../Common/Form/Autocomplete';
import { LocationProductQueryParams } from '../../../interfaces/LocationProduct';
import { ILocation } from '../../../containers/Location/LocationAssets';
import Input from '../../Common/Form/Input';
import { IProductEPI } from '../../../containers/Product/ProductAssets';
import Select from '../../Common/Form/Select';
import { CreateConsumptionTasksParams } from '../../../containers/Consumption/ConsumptionAssets';
import { ConsumptionType } from '../../../enums/ConsumptionType';
import { ProductEPIQueryParams } from '../../../interfaces/ProductEpiQueryParams';
import { IEmployeeWithEpis } from '../../../containers/EpiControl/EpiControlAssets';
import Button from '../../Common/Button/Button';

interface SelectedProduct {
  id: string
  fullDescription: string
  quantity: number
}

export interface ConsumptionEPIDrawerProps {
  open: boolean
  onClose: () => void;
  getLocations: (queryParams?: LocationProductQueryParams) => void;
  employee?: IEmployeeWithEpis;
  locations: ILocation[];
  epiProducts: IProductEPI[];
  getEPIProducts: (queryParams?: ProductEPIQueryParams) => void;
  createConsumptionTasks: (params: CreateConsumptionTasksParams) => void;
}

const ConsumptionEPIDrawer = ({
	open,
	onClose,
	getLocations,
	employee,
	locations,
	epiProducts,
	getEPIProducts,
	createConsumptionTasks,
}: ConsumptionEPIDrawerProps): JSX.Element | null => {
	const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>([]);

	const formik = useFormik({
		initialValues: {
			locationId: '',
			quantity: 0,
			operatorName: employee?.fullName || '',
			productId: '',
			type: undefined,
			notes: '',
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			if (values.type !== undefined && values.locationId && selectedProducts.length > 0) {
				const tasks = selectedProducts.map((product) => ({
					productId: product.id,
					quantity: Number(product.quantity),
				}));
				createConsumptionTasks({
					type: values.type,
					locationId: values.locationId,
					employeeId: employee?.id,
					operatorName: values.operatorName,
					tasks,
					notes: values.notes,
				});
				onClose();
			}
			formik.resetForm();
			setSelectedProducts([]);
		},
	});

	useEffect(() => {
		if (open) {
			getLocations({ skip: 0, name: 'Balcão' });
		}
		if (employee?.id) {
			getEPIProducts({
				employeeId: employee.id,
			});
		}
	}, [getLocations, open, employee, getEPIProducts]);

	const handleAddProduct = useCallback(():void => {
		const { productId } = formik.values;
		const { quantity } = formik.values;
		const productSelected = epiProducts.find((product) => product.id === productId);
		if (productSelected && quantity) {
			setSelectedProducts([...selectedProducts, {
				id: productSelected.id,
				fullDescription: `${productSelected.code} - ${productSelected.description}`,
				quantity,
			}]);
			formik.setFieldValue('productId', '');
			formik.setFieldValue('quantity', '');
		}
	}, [formik, epiProducts, selectedProducts]);

	return (
		<FormikContext.Provider value={formik}>
			<Drawer
				variant="temporary"
				anchor="right"
				open={open}
				onClose={onClose}
				sx={{
					'& .MuiDrawer-paper': {
						width: '750px',
					},
				}}
			>
				<Form noValidate>
					<Box
						sx={{
							marginTop: '60px',
							width: '100%',
							padding: 4,
							height: '100%',
							boxSizing: 'border-box',
							overflowY: 'auto',
						}}
						role="presentation"
					>
						<Card variant="outlined">
							<CardHeader
								avatar={(
									<Avatar sx={{ bgcolor: 'primary.main' }}>
										<AddModerator />
									</Avatar>
								)}
								title={(
									<Typography variant="h5" component="div">
										Consumo de EPI
									</Typography>
								)}
								subheader="Adicione os EPIs para consumo"
							/>
							<Divider />
							<CardContent>
								<Grid container spacing={3}>
									<Grid item xs={6}>
										<Select
											name="type"
											label="Tipo de consumo"
											labelKey="name"
											valueKey="id"
											options={[
												{
													id: ConsumptionType.REQUISITION,
													name: 'Requisição',
												},
												{
													id: ConsumptionType.DEVOLUTION,
													name: 'Devolução',
												},
											]}
											required
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											name="locationId"
											label={formik.values.type === ConsumptionType.DEVOLUTION ? 'Localização de origem' : 'Localização de destino'}
											valueKey="id"
											valueLabel="barCode"
											formatBarCode
											labelKey="name"
											options={locations}
											closeOnSelect
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={12}>
										<Input.InputField
											name="operatorName"
											label="Colaborador"
											inputProps={{ readOnly: true }}
											fullWidth
											required
										/>
									</Grid>
								</Grid>
								<Box sx={{ mt: 3, mb: 2 }}>
									<TableContainer component={Paper} variant="outlined" sx={{ mb: 2 }}>
										<Table stickyHeader size="small">
											<TableHead>
												<TableRow>
													<TableCell sx={{ width: '65%' }}>Descrição</TableCell>
													<TableCell align="right" sx={{ width: '30%' }}>Quantidade</TableCell>
													<TableCell align="right" sx={{ width: '5%' }}>Ações</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{selectedProducts.map((product, index) => (
													<TableRow key={product.id}>
														<TableCell sx={{ padding: '6px 16px' }}>{product.fullDescription}</TableCell>
														<TableCell align="right">{product.quantity}</TableCell>
														<TableCell align="right">
															<IconButton
																onClick={() => {
																	setSelectedProducts(selectedProducts
																		.filter((_, i) => i !== index));
																}}
																color="error"
																size="small"
															>
																<Delete />
															</IconButton>
														</TableCell>
													</TableRow>
												))}
												<TableRow>
													<TableCell>
														<FormControl fullWidth margin="dense">
															<Autocomplete
																name="productId"
																label="EPI"
																options={epiProducts}
																labelKey="description"
																valueKey="id"
																valueLabel="code"
																closeOnSelect
															/>
														</FormControl>
													</TableCell>
													<TableCell align="right">
														<Input.InputField
															name="quantity"
															label="Quantidade"
															variant="outlined"
															margin="dense"
															type="number"
															inputProps={{ min: 0 }}
															fullWidth
														/>
													</TableCell>
													<TableCell align="right">
														<IconButton
															onClick={handleAddProduct}
															color="primary"
															size="small"
															disabled={!formik.values.productId
																|| (!formik.values.quantity || formik.values.quantity <= 0)}
														>
															<Check />
														</IconButton>
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</Box>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Input.InputField
											name="notes"
											label="Observações"
											multiline
											rows={2}
											maxLength={100}
											placeholder="Adicione uma observação (máximo 100 caracteres)"
											fullWidth
										/>
									</Grid>
								</Grid>

								<Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
									<Button
										variant="contained"
										type="submit"
										color="primary"
										size="large"
										disabled={
											selectedProducts.length === 0
											|| formik.values.type === undefined
											|| !formik.values.locationId
											|| !formik.values.operatorName
										}
									>
										Criar
									</Button>
								</Box>
							</CardContent>
						</Card>
					</Box>
				</Form>
			</Drawer>
		</FormikContext.Provider>
	);
};

ConsumptionEPIDrawer.defaultProps = {
	employee: undefined,
};

export default ConsumptionEPIDrawer;
