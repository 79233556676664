import React, { useMemo } from 'react';
import { Formik, Form, FormikConsumer } from 'formik';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Input from '../Common/Form/Input';
import {
	EditProduct,
	IMeasure,
	IProduct,
	IProductType,
	initialValues,
	validationSchema,
} from '../../containers/Product/ProductAssets';
import Switch from '../Common/Form/Switch';
import { IFilterField } from '../../interfaces/BudgetOrderFourFields';
import Autocomplete from '../Common/Form/Autocomplete';
import { ConversionType } from '../../enums/ConversionType';
import { IBranch } from '../../containers/Branch/BranchAssets';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { BRANCH_ID_KEY, COMPANY_ID_KEY } from '../../services/auth';
import FileUpload from '../Common/Form/DropzoneUpload';
import Select from '../Common/Form/Select';
import { ReplacementTimeUnit } from '../../enums/ReplacementTimeUnit';

const caValidityOptions = [
	{ value: 1, label: '1 Ano' },
	{ value: 2, label: '2 Anos' },
	{ value: 3, label: '3 Anos' },
	{ value: 4, label: '4 Anos' },
	{ value: 5, label: '5 Anos' },
];

const replacementTimeUnitOptions = [
	{ value: ReplacementTimeUnit.DAY, label: 'Dia' },
	{ value: ReplacementTimeUnit.MONTH, label: 'Mês' },
	{ value: ReplacementTimeUnit.YEAR, label: 'Ano' },
	{ value: ReplacementTimeUnit.UNDEFINED, label: 'Indeterminado' },
];

const conversionTypeOptions = [
	{ label: 'Divisor', value: ConversionType.DIVIDER },
	{ label: 'Multiplicador', value: ConversionType.MULTIPLIER },
];

interface ProductFormProps {
	product?: IProduct;
	accountingAccounts: IFilterField[];
	measures: IMeasure[];
	productTypes: IProductType[];
	branches: IBranch[];
	companies: ICompanyWithoutDetails [];
	onSubmit: (values: EditProduct) => void;
	loading: boolean;
}

const ProductForm = ({
	product,
	accountingAccounts,
	measures,
	productTypes,
	branches,
	companies,
	onSubmit,
	loading,
}: ProductFormProps): JSX.Element => {
	const companyId = localStorage.getItem(COMPANY_ID_KEY);
	const branchId = localStorage.getItem(BRANCH_ID_KEY);

	const initialValuesLoad = useMemo((): EditProduct => (product ? {
		companyId: product.companyId,
		branchId: product.branchId,
		code: product.code,
		description: product.description,
		erpCode: product.erpCode,
		erpDescription: product.erpDescription,
		barCode: product.barCode,
		primaryMeasureId: product.primaryMeasureId,
		secondaryMeasureId: product.secondaryMeasureId,
		typeId: product.typeId,
		accountingAccountId: product.accountingAccountId,
		apportionmentControl: product.apportionmentControl,
		conversionIndex: product.conversionIndex,
		conversionType: product.conversionType,
		qualityControl: product.qualityControl,
		locateControl: product.locateControl,
		image: null,
		epiControl: product.epiControl,
		caValidity: product.caValidity,
		replacementTime: product.replacementTime,
		replacementTimeUnit: product.replacementTimeUnit,
		disposable: product.disposable,
	} : {
		...initialValues,
		companyId: companyId || '',
		branchId: branchId || '',
	}), [product, companyId, branchId]);

	return (
		<Formik
			initialValues={initialValuesLoad}
			validationSchema={validationSchema}
			onSubmit={(values: EditProduct) => {
				onSubmit(values);
			}}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{(formikProps) => (
				<Form noValidate>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Autocomplete
								name="companyId"
								label="Empresa"
								options={companies}
								labelKey="name"
								valueKey="id"
								valueLabel="code"
								readOnly
								required
							/>
						</Grid>
						<Grid item xs={6}>
							<Autocomplete
								name="branchId"
								label="Filial"
								options={branches}
								labelKey="name"
								valueKey="id"
								valueLabel="code"
								readOnly
								required
							/>
						</Grid>
						<Grid item xs={6}>
							<Input.InputField
								id="code"
								name="code"
								label="Código"
								required
								fullWidth
							/>
						</Grid>
						<Grid item xs={6}>
							<Input.InputField
								id="description"
								name="description"
								label="Descrição"
								required
								fullWidth
							/>
						</Grid>
						<Grid item xs={6}>
							<Input.InputField
								id="familyCode"
								name="familyCode"
								label="Código Família"
								fullWidth
							/>
						</Grid>
						<Grid item xs={6}>
							<Input.InputField
								id="familyDescription"
								name="familyDescription"
								label="Descrição Família"
								fullWidth
							/>
						</Grid>
						<Grid item xs={6}>
							<Input.InputField
								id="erpCode"
								name="erpCode"
								label="Código ERP"
								fullWidth
							/>
						</Grid>
						<Grid item xs={6}>
							<Input.InputField
								id="erpDescription"
								name="erpDescription"
								label="Descrição ERP"
								fullWidth
							/>
						</Grid>
						<Grid item xs={6}>
							<Input.InputField
								id="barCode"
								name="barCode"
								label="Código de Barras"
								required
								fullWidth
							/>
						</Grid>
						<Grid item xs={3}>
							<Autocomplete
								name="primaryMeasureId"
								label="1ª Unidade de Medida"
								options={measures}
								labelKey="description"
								valueKey="id"
								required
							/>
						</Grid>
						<Grid item xs={3}>
							<Autocomplete
								name="secondaryMeasureId"
								label="2ª Unidade de Medida"
								options={measures}
								labelKey="description"
								valueKey="id"
							/>
						</Grid>
						<Grid item xs={6}>
							<Autocomplete
								name="typeId"
								label="Tipo"
								options={productTypes}
								labelKey="description"
								valueKey="id"
								required
							/>
						</Grid>
						<Grid item xs={6}>
							<Autocomplete
								name="accountingAccountId"
								label="Conta Contábil"
								options={accountingAccounts}
								labelKey="name"
								valueKey="code"
							/>
						</Grid>
						<Grid item xs={6}>
							<Select
								name="conversionType"
								label="Tipo de Conversão"
								options={conversionTypeOptions}
								labelKey="label"
								valueKey="value"
							/>
						</Grid>
						<Grid item xs={6}>
							<Input.InputField
								id="conversionIndex"
								name="conversionIndex"
								label="Índice de Conversão"
								type="number"
								InputProps={{
									inputProps: { min: 0 },
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={3}>
							<Switch
								name="qualityControl"
								label="Controle de Qualidade"
							/>
						</Grid>
						<Grid item xs={3}>
							<Switch
								name="locateControl"
								label="Controle de Localização"
							/>
						</Grid>
						<Grid item xs={3}>
							<Switch
								name="apportionmentControl"
								label="Controle de Rateio"
							/>
						</Grid>
						<Grid item xs={3}>
							<Switch
								name="epiControl"
								label="Controle de EPI"
								onChange={(e) => {
									if (!e.target.checked) {
										formikProps.setFieldValue('caValidity', '');
										formikProps.setFieldValue('replacementTime', '');
										formikProps.setFieldValue('replacementTimeUnit', '');
										formikProps.setFieldValue('disposable', false);
									}
								}}
							/>
						</Grid>
						<FormikConsumer>
							{({ values }) => values.epiControl && (
								<>
									<Grid item xs={4}>
										<Select
											name="caValidity"
											label="Validade do C.A. (anos)"
											options={caValidityOptions}
											labelKey="label"
											valueKey="value"
											required
										/>
									</Grid>
									<Grid item xs={4}>
										<Input.InputField
											id="replacementTime"
											name="replacementTime"
											label="Tempo de Substituição"
											type="number"
											fullWidth
											required={values.replacementTimeUnit
												!== ReplacementTimeUnit.UNDEFINED}
											disabled={values.replacementTimeUnit
												=== ReplacementTimeUnit.UNDEFINED}
											InputProps={{
												inputProps: { min: 0 },
											}}
										/>
									</Grid>
									<Grid item xs={2}>
										<Select
											name="replacementTimeUnit"
											label="Unidade"
											options={replacementTimeUnitOptions}
											labelKey="label"
											valueKey="value"
											required
											onChange={(e) => {
												if (Number(e.target.value) === ReplacementTimeUnit.UNDEFINED) {
													formikProps.setFieldValue('replacementTime', '');
												}
											}}
										/>
									</Grid>
									<Grid item xs={2}>
										<Switch
											name="disposable"
											label="Descartável"
										/>
									</Grid>
								</>
							)}
						</FormikConsumer>
						<Grid item xs={12}>
							<FileUpload
								name="image"
								currentFile={product?.imageB64}
							/>
						</Grid>
					</Grid>
					<Box marginTop={2}>
						<LoadingButton
							loading={loading}
							type="submit"
							variant="contained"
							color="primary"
						>
							Salvar
						</LoadingButton>
					</Box>
				</Form>
			)}
		</Formik>
	);
};

ProductForm.defaultProps = {
	product: undefined,
};

export default ProductForm;
