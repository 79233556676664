/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	Grid,
	CircularProgress,
} from '@mui/material';
import { Form, FormikContext, useFormik } from 'formik';
import Autocomplete from '../../Common/Form/Autocomplete';
import { ICompanyWithoutDetails } from '../../../containers/Company/CompanyAssets';
import { IBranch } from '../../../containers/Branch/BranchAssets';
import { IEmployee, IEmployeeError } from '../../../containers/Employee/EmployeeAssets';
import LoadingButton from '../../Common/LoadingButton/LoadingButton';

interface EmployeeXLSModalProps {
	open: boolean;
	handleClose: () => void;
	createEmployeesFromXls(
		data: FormData
	): void;
	loading: boolean;
	companies: ICompanyWithoutDetails[],
	branches: IBranch[],
	employeesWithError: IEmployeeError[];
	getBranches: (companyId: string) => void;
	getCompanies: () => void;
	successMessage: string;
	existingEmployees: IEmployee[];
	createEmployeesBatch(data: IEmployee[]): void;
}

const EmployeeXLSModal = ({
	open,
	handleClose,
	createEmployeesFromXls,
	loading,
	companies,
	branches,
	employeesWithError,
	getBranches,
	getCompanies,
	successMessage,
	existingEmployees,
	createEmployeesBatch,
}: EmployeeXLSModalProps): JSX.Element => {
	const formik = useFormik({
		initialValues: {
			registrationCompanyId: '',
			registrationBranchId: '',
			file: null,
		},
		onSubmit: (values) => {
			if (values.file) {
				const formData = new FormData();
				formData.append('file', values.file);
				formData.append('registrationCompanyId', values.registrationCompanyId);
				formData.append('registrationBranchId', values.registrationBranchId);
				createEmployeesFromXls(formData);
			}
		},
	});

	useEffect(() => {
		if (open) {
			getCompanies();
			if (formik.values.registrationCompanyId) {
				getBranches(formik.values.registrationCompanyId);
				formik.setFieldValue('registrationBranchId', '');
			}
		} else {
			formik.setFieldValue('registrationCompanyId', '');
			formik.setFieldValue('registrationBranchId', '');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, getCompanies, formik.values.registrationCompanyId, getBranches]);

	const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			formik.setFieldValue('file', file, false);
			formik.submitForm();
		}
	}, [formik]);

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
			<FormikContext.Provider value={formik}>
				<Form onSubmit={formik.handleSubmit}>
					<DialogTitle>{existingEmployees.length === 0 ? 'Enviar Planilha de Colaboradores' : 'Confirmar atualização'}</DialogTitle>
					<DialogContent>
						{existingEmployees.length === 0 ? (
							<>
								<Typography sx={{ my: 2 }}>
									Selecione a empresa e filial de registro dos colaboradores:
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Autocomplete
											label="Empresa"
											name="registrationCompanyId"
											valueKey="id"
											valueLabel="code"
											labelKey="name"
											options={companies}
											closeOnSelect
											required
										/>
									</Grid>
									<Grid item xs={12}>
										<Autocomplete
											label="Filial"
											name="registrationBranchId"
											valueKey="id"
											valueLabel="code"
											labelKey="name"
											options={branches}
											closeOnSelect
											required
										/>
									</Grid>
									<Grid sx={{ mt: 2 }} item xs={12}>
										{loading ? (
											<CircularProgress />
										) : (
											<>
												<input
													type="file"
													accept=".xls"
													onChange={handleFileChange}
													style={{ display: 'none' }}
													id="upload-xls"
													disabled={
														!formik.values.registrationCompanyId
												|| !formik.values.registrationBranchId
													}
												/>
												<label htmlFor="upload-xls">
													<Button
														variant="contained"
														component="span"
														disabled={
															!formik.values.registrationCompanyId
													|| !formik.values.registrationBranchId
														}
													>
														Selecionar Arquivo
													</Button>
												</label>
											</>
										)}
									</Grid>
									<Grid item xs={12}>
										{employeesWithError.length > 0 && (
											<>
												<Typography color="error" sx={{ my: 2 }}>Registros inválidos:</Typography>
												<div style={{
													maxHeight: '200px',
													overflowY: 'auto',
													border: '1px solid #ccc',
													padding: '8px',
													borderRadius: '4px',
												}}
												>
													{employeesWithError?.map((error) => (
														<Typography
															key={error.id}
															sx={{ mb: 1 }}
														>
															{`${error.fullName} - ${error.message}`}
														</Typography>
													))}
												</div>
											</>
										)}

										{successMessage && formik.values.registrationCompanyId && employeesWithError.length === 0 && <Typography color="green">{successMessage}</Typography>}
									</Grid>
								</Grid>
							</>
						) : (
							<Typography>
								Já existem colaboradores com a matrícula informada.
								Deseja atualizar esses registros?
							</Typography>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>Fechar</Button>
						{existingEmployees.length > 0
						&& (
							<LoadingButton
								loading={loading}
								variant="contained"
								onClick={() => createEmployeesBatch(existingEmployees)}
							>
								Atualizar
							</LoadingButton>
						)}
					</DialogActions>
				</Form>
			</FormikContext.Provider>
		</Dialog>
	);
};

export default EmployeeXLSModal;
