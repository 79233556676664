import { AxiosResponse } from 'axios';
import { InventoryTaskStatus } from '../enums/InventoryTaskStatus';
import { ConsumptionProductionTaskQueryParams } from '../interfaces/ConsumptionProductionTaskQueryParams';
import api from './api';
import { IMessageResponse } from '../interfaces/MessageResponse';
import {
	ConsumptionTasksResponse,
	IStatusCount,
	CreateConsumptionTasksParams,
	SignatureResponse,
} from '../containers/Consumption/ConsumptionAssets';

export const createConsumptionTasks = (
	data: CreateConsumptionTasksParams,
): Promise<AxiosResponse<IMessageResponse>> => api.post<CreateConsumptionTasksParams, AxiosResponse<IMessageResponse>>('/consumption', data);

export const getConsumptionProduction = (
	params: ConsumptionProductionTaskQueryParams,
	status?: InventoryTaskStatus,
): Promise<AxiosResponse<ConsumptionTasksResponse>> => api.get<ConsumptionTasksResponse>(status ? `/inventory-task/consumption-production/${status}` : '/inventory-task/consumption-production/', {
	params,
});

export const getConsumptionCountByStatus = (): Promise<AxiosResponse<IStatusCount>> => api.get<IStatusCount>('/consumption/count-by-status');

export const getConsumptionSignature = (
	id: string,
): Promise<AxiosResponse<SignatureResponse>> => api.get<SignatureResponse>(`/consumption/signature/${id}`);
