import React, { ReactElement, useMemo } from 'react';
import {
	GridColDef,
	GridRenderCellParams,
	GridColumnVisibilityModel,
	GridValueFormatterParams,
	GridActionsCellItem,
} from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import InfoIcon from '@mui/icons-material/Info';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import ReceiptIcon from '@mui/icons-material/Receipt';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import { Link } from 'react-router-dom';
import { Password, Print } from '@mui/icons-material';
import { defaultColumns } from '../constants/purchaseOrdersColumns';
import { purchaseOrderApprovalStatusDetailed } from '../constants/purchaseOrderApprovalStatus';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';
import { CurrentUserProps } from '../containers/User/UserAssets';

type ColumnData = {
    columns: GridColDef[];
    defaultVisibility: GridColumnVisibilityModel;
};

type UseColumnsOptions = {
    defaultColumnsOverride?: GridColDef[];
    includeStatusColumn?: boolean;
    isDetailed?: boolean;
    onApportionmentClick?: ((id: string) => void) | null;
    onDetailsClick?: ((id: string) => void) | null;
    columnsVisibility: string[];
    optionalColumnsVisibility?: string[];
    apportionAction?: boolean;
    onNFDownloadClick?: ((id: string, invoiceNumber?: string) => void) | null;
    onNFViewAsHTMLClick?: ((id: string, invoiceNumber?: string) => void) | null;
    onEditClick?: ((id: string) => void) | null;
    onDeleteClick?: ((id: string) => void) | null;
    onCancelClick?: ((id: string) => void) | null;
	onChangeActiveStatus?: ((id: string, active: boolean) => void) | null;
    onApproveClick?: ((id: string) => void) | null;
    onRejectClick?: ((id: string) => void) | null;
    shouldShowApproveReject?: ((rowData: any) => boolean) | null;
    editIcon?: ReactElement;
    editTooltip?: string;
	onResetPassword?: ((user: CurrentUserProps) => void) | null;
	onPrintLabel?: ((barCode: string) => void) | null;
	onConsumptionClick?: ((id: string) => void) | null;
};

const useColumns = ({
	defaultColumnsOverride = defaultColumns,
	includeStatusColumn = false,
	isDetailed = false,
	onApportionmentClick = null,
	onDetailsClick = null,
	columnsVisibility,
	optionalColumnsVisibility,
	apportionAction = false,
	onNFDownloadClick = null,
	onNFViewAsHTMLClick = null,
	onEditClick = null,
	onDeleteClick = null,
	onCancelClick = null,
	onChangeActiveStatus = null,
	onApproveClick = null,
	onRejectClick = null,
	shouldShowApproveReject = null,
	editIcon = <EditIcon />,
	editTooltip = 'Editar',
	onResetPassword = null,
	onPrintLabel = null,
	onConsumptionClick = null,
}: UseColumnsOptions): ColumnData => {
	const createColumnsWithStatusAndActions = (
		statusColumn: GridColDef | null,
		actionsColumn: GridColDef,
	): GridColDef[] => {
		const newColumns = [...defaultColumnsOverride];
		const allVisibleAndOptionalColumns = new Set(
			[...columnsVisibility, ...(optionalColumnsVisibility || []),
			],
		);
		const filteredNewColumns = optionalColumnsVisibility
			? newColumns.filter((column) => allVisibleAndOptionalColumns.has(column.field))
			: newColumns;
		if (statusColumn) {
			const desiredIndex = filteredNewColumns.findIndex((col) => col.field === 'nrOrder') + 1;
			filteredNewColumns.splice(desiredIndex, 0, statusColumn);
		}
		filteredNewColumns.push(actionsColumn);

		return filteredNewColumns;
	};

	const columns: GridColDef[] = useMemo(() => {
		let statusColumn: GridColDef | null = null;
		if (includeStatusColumn) {
			statusColumn = {
				type: 'number',
				field: 'status',
				headerName: 'Status',
				valueFormatter: (params: GridValueFormatterParams) => {
					let status = purchaseOrderApprovalStatusDetailed[params.value];

					if (!isDetailed) {
						if (params.value === 0) {
							status = 'Ativo';
						} else if (params.value === 3 || params.value === 4) {
							status = 'Estouro';
						}
					}

					return status;
				},
				flex: 0.4,
				sortable: false,
				align: 'center',
				renderCell: (params: GridRenderCellParams) => (
					<TruncateTooltipCell value={params.formattedValue} />
				),
			};
		}

		const actionsColumn: GridColDef = {
			field: 'actions',
			headerName: 'Ações',
			width: 140,
			sortable: false,
			renderCell: (params: GridRenderCellParams<string>) => (
				<>
					{onEditClick && (
						<Tooltip title={editTooltip}>
							<Link to={`edit/${params.id}`}>
								<GridActionsCellItem
									icon={editIcon}
									label="Editar"
									color="primary"
								/>
							</Link>
						</Tooltip>
					)}
					{onResetPassword && (
						<Tooltip title="Redefinir senha">
							<GridActionsCellItem
								label="Redefinir senha"
								onClick={() => onResetPassword(params.row as CurrentUserProps)}
								icon={<Password />}
							/>
						</Tooltip>
					)}
					{onChangeActiveStatus && (
						<Tooltip title={params.row.active ? 'Desativar' : 'Ativar'}>
							<GridActionsCellItem
								icon={params.row.active ? <ToggleOnIcon /> : <ToggleOffIcon />}
								label={params.row.active ? 'Desativar' : 'Ativar'}
								color={params.row.active ? 'primary' : 'default'}
								onClick={() => onChangeActiveStatus(params.id.toString(), !params.row.active)}
							/>
						</Tooltip>
					)}
					{shouldShowApproveReject && shouldShowApproveReject(params.row) && (
						<>
							{onApproveClick && (
								<Tooltip title="Aprovar">
									<GridActionsCellItem
										icon={<CheckIcon />}
										label="Aprovar"
										color="primary"
										onClick={() => onApproveClick(params.id.toString())}
									/>
								</Tooltip>
							)}
							{onRejectClick && (
								<Tooltip title="Reprovar">
									<GridActionsCellItem
										icon={<CloseIcon />}
										label="Reprovar"
										color="error"
										onClick={() => onRejectClick(params.id.toString())}
									/>
								</Tooltip>
							)}
						</>
					)}
					{onDeleteClick && (
						<Tooltip title="Excluir">
							<GridActionsCellItem
								icon={<DeleteIcon />}
								label="Excluir"
								color="error"
								onClick={() => onDeleteClick(params.id.toString())}
							/>
						</Tooltip>
					)}
					{onCancelClick && (
						<Tooltip title="Cancelar">
							<GridActionsCellItem
								icon={<CancelIcon />}
								label="Cancelar"
								color="error"
								onClick={() => onCancelClick(params.id.toString())}
							/>
						</Tooltip>
					)}
					{apportionAction && (
						<Tooltip title="Ratear">
							<Link to={params.id.toString()}>
								<GridActionsCellItem
									icon={<BackupTableIcon />}
									label="Ratear"
									className="textPrimary"
									color="primary"
								/>
							</Link>
						</Tooltip>
					)}
					{onNFViewAsHTMLClick && (
						<Tooltip title="Visualizar DANFE">
							<GridActionsCellItem
								onClick={() => onNFViewAsHTMLClick(
									params.id.toString(),
									params.row.invoiceNumber,
								)}
								icon={<ReceiptIcon />}
								label="Visualizar DANFE"
								className="textPrimary"
								color="primary"
							/>
						</Tooltip>
					)}
					{onNFDownloadClick && (
						<Tooltip title="Baixar XML">
							<GridActionsCellItem
								onClick={() => onNFDownloadClick(params.id.toString(), params.row.invoiceNumber)}
								icon={<SimCardDownloadIcon />}
								label="Baixar XML"
								className="textPrimary"
								color="primary"
							/>
						</Tooltip>
					)}
					{onApportionmentClick && (
						<Tooltip title="Rateios">
							<IconButton onClick={() => onApportionmentClick(params.id.toString())}>
								<WorkspacesIcon />
							</IconButton>
						</Tooltip>
					)}
					{onPrintLabel && (
						<Tooltip title="Imprimir Etiqueta">
							<GridActionsCellItem
								icon={<Print />}
								label="Imprimir"
								color="primary"
								onClick={() => onPrintLabel(params.row.barCode)}
							/>
						</Tooltip>
					)}
					{onConsumptionClick && (
						<Tooltip title="Consumo de EPI">
							<GridActionsCellItem
								icon={<MoveToInboxIcon sx={{ fontSize: '1.6rem' }} />}
								label="Consumo de EPI"
								color="primary"
								onClick={() => onConsumptionClick(params.id.toString())}
							/>
						</Tooltip>
					)}
					{onDetailsClick && (
						<Tooltip title="Detalhes">
							<IconButton onClick={() => onDetailsClick(params.id.toString())}>
								<InfoIcon color="primary" />
							</IconButton>
						</Tooltip>
					)}
				</>
			),
		};

		return createColumnsWithStatusAndActions(statusColumn, actionsColumn);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		apportionAction,
		includeStatusColumn,
		isDetailed,
		onApportionmentClick,
		onDetailsClick,
		onNFDownloadClick,
		onEditClick,
		onDeleteClick,
		onChangeActiveStatus,
		defaultColumnsOverride,
	]);

	const defaultVisibility: GridColumnVisibilityModel = useMemo(
		() => columns.reduce((visibility, column) => ({
			...visibility,
			[column.field]: columnsVisibility.includes(column.field),
		}), {} as GridColumnVisibilityModel),
		[columns, columnsVisibility],
	);

	return { columns, defaultVisibility };
};

export default useColumns;
