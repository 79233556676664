import React, { useEffect } from 'react';
import {
	Box,
	Grid,
	Paper,
	Typography,
} from '@mui/material';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import { useParams } from 'react-router-dom';
import Input from '../Common/Form/Input';
import LoadingButton from '../Common/LoadingButton/LoadingButton';
import { IEmployee } from '../../containers/Employee/EmployeeAssets';
import { Loading } from '../Common/Loading';
import { DatePicker } from '../Common/Form/DatePicker';
import Autocomplete from '../Common/Form/Autocomplete';
import { ICompanyWithoutDetails } from '../../containers/Company/CompanyAssets';
import { IBranch } from '../../containers/Branch/BranchAssets';
import { filterObject } from '../../helpers/Utils';

interface EmployeeEditProps {
	loading: boolean;
	employee?: IEmployee;
	getEmployeeById(id: string): void;
	createEmployee(data: IEmployee): void;
	updateEmployee(id: string, data: Partial<IEmployee>): void;
	getCompanies(): void;
	getBranches: (companyId: string) => void;
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
}

const EmployeeEdit = ({
	loading,
	employee,
	getEmployeeById,
	createEmployee,
	getCompanies,
	getBranches,
	updateEmployee,
	companies,
	branches,
}: EmployeeEditProps): JSX.Element => {
	const { id } = useParams();

	const formik = useFormik({
		initialValues: employee ? {
			...employee,
		} : {
			registration: '',
			fullName: '',
			birthDate: '',
			admissionDate: '',
			department: '',
			costCenter: '',
			position: '',
			registrationBranchId: '',
			registrationCompanyId: '',
		},
		onSubmit: (values) => {
			const data = {
				registration: values.registration,
				department: values.department,
				fullName: values.fullName,
				costCenter: values.costCenter,
				position: values.position,
				birthDate: values.birthDate,
				admissionDate: values.admissionDate,
				registrationBranchId: values.registrationBranchId,
				registrationCompanyId: values.registrationCompanyId,
			};
			if (!id) {
				createEmployee(data);
			} else {
				updateEmployee(id, filterObject(data));
			}
		},
		enableReinitialize: true,
	});

	useEffect(() => {
		if (id) {
			getEmployeeById(id);
		}
	}, [id, getEmployeeById]);

	useEffect(() => {
		getCompanies();
	}, [getCompanies]);

	useEffect(() => {
		if (formik.values.registrationCompanyId) {
			getBranches(formik.values.registrationCompanyId);
		}
	}, [formik.values.registrationCompanyId, getBranches]);

	if (id && !employee) {
		return (
			<Loading />
		);
	}

	return (
		<Box className="content">
			<Typography variant="h4" color="primary" gutterBottom>
				{id ? 'Editar Colaborador' : 'Novo Colaborador'}
			</Typography>
			<Paper sx={{ p: 2 }}>
				<FormikContext.Provider value={formik}>
					<Form>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={3}>
								<Input.InputField
									name="registration"
									label="Matrícula"
									required
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sm={9}>
								<Input.InputField
									name="fullName"
									label="Nome Completo"
									required
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<DatePicker
									name="birthDate"
									label="Data de Nascimento"
									required
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<DatePicker
									name="admissionDate"
									label="Data de Admissão"
									required
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Input.InputField
									name="department"
									label="Departamento"
									required
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Input.InputField
									name="costCenter"
									label="Centro de Custo"
									required
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Input.InputField
									name="position"
									label="Cargo"
									required
									fullWidth
								/>
							</Grid>
							<Grid item xs={6}>
								<Autocomplete
									label="Empresa de Registro"
									name="registrationCompanyId"
									options={companies}
									labelKey="name"
									valueKey="id"
									valueLabel="code"
								/>
							</Grid>
							<Grid item xs={6}>
								<Autocomplete
									label="Filial de Registro"
									name="registrationBranchId"
									options={branches}
									labelKey="name"
									valueKey="id"
									valueLabel="code"
								/>
							</Grid>
							<Grid item xs={12}>
								<Box display="flex" justifyContent="flex-end">
									<LoadingButton
										type="submit"
										variant="contained"
										loading={loading}
										disabled={
											!formik.values.registration
											|| !formik.values.fullName
											|| !formik.values.birthDate
											|| !formik.values.admissionDate
											|| !formik.values.department
											|| !formik.values.costCenter
											|| !formik.values.position
											|| !formik.values.registrationCompanyId
											|| !formik.values.registrationBranchId
										}
									>
										Salvar
									</LoadingButton>
								</Box>
							</Grid>
						</Grid>
					</Form>
				</FormikContext.Provider>
			</Paper>
		</Box>
	);
};

EmployeeEdit.defaultProps = {
	employee: undefined,
};

export default EmployeeEdit;
