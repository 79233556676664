import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import EmployeePresentational from '../../components/Employee/Employee';
import {
	EmployeeQueryParams, IEmployee, IEmployeeError,
} from './EmployeeAssets';
import {
	createEmployeesBatch,
	createEmployeesFromXls,
	deleteEmployee,
	downloadEmployeeXls,
	getEmployees,
} from '../../services/employee';
import { ICompanyWithoutDetails } from '../Company/CompanyAssets';
import { IBranch } from '../Branch/BranchAssets';
import { getCompanies } from '../../services/company';
import { getBranches } from '../../services/branch';

enum ActionType {
	LOADING,
	EMPLOYEE,
	COMPANY,
	BRANCH,
	EMPLOYEES,
	EXISTING_EMPLOYEE,
	EMPLOYEE_WITH_ERROR
}

interface IState {
	loading: boolean;
	employees: IEmployee[];
	pages: number;
	page: number;
	take: number;
	companies: ICompanyWithoutDetails[];
	branches: IBranch[];
	employeesWithError: IEmployeeError[];
	existingEmployees: IEmployee[];
	successMessage: string;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.EMPLOYEE_WITH_ERROR; payload: { employeesWithError: IEmployeeError[] } }
	| { type: ActionType.COMPANY; payload: { companies: ICompanyWithoutDetails[] } }
	| { type: ActionType.BRANCH; payload: { branches: IBranch[] } }
	| { type: ActionType.EMPLOYEES; payload: {
		employeesWithError: IEmployeeError[];
		existingEmployees: IEmployee[];
		successMessage: string;
	} }
	| { type: ActionType.EMPLOYEE; payload: {
		employees: IEmployee[];
		pages: number;
		take: number;
		page: number;
	} }
	| { type: ActionType.EXISTING_EMPLOYEE; payload: { existingEmployees: IEmployee[] } };

interface IEmployeeActions {
	setLoading(loading: boolean): void;
	setExistingEmployees(existingEmployees: IEmployee[]): void;
	setEmployeesWithError(employeesWithError: IEmployeeError[]): void;
	getEmployees(params: EmployeeQueryParams): void;
	createEmployeesFromXls(data: FormData): void;
	createEmployeesBatch(data: IEmployee[]): void;
	getCompanies(): void;
	getBranches(companyId: string): void;
	downloadEmployeeXls(): void;
	deleteEmployee(employeeId: string): void;
	handleEdit(id: string): void;
}

const initialState: IState = {
	loading: false,
	employees: [],
	pages: 0,
	page: 0,
	take: 10,
	companies: [],
	branches: [],
	employeesWithError: [],
	existingEmployees: [],
	successMessage: '',
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.EMPLOYEE:
			return {
				...state,
				employees: action.payload.employees,
				pages: action.payload.pages,
				take: action.payload.take,
				page: action.payload.page,
			};
		case ActionType.COMPANY:
			return {
				...state,
				companies: action.payload.companies,
			};
		case ActionType.BRANCH:
			return {
				...state,
				branches: action.payload.branches,
			};
		case ActionType.EMPLOYEES:
			return {
				...state,
				employeesWithError: action.payload.employeesWithError,
				successMessage: action.payload.successMessage,
				existingEmployees: action.payload.existingEmployees,
			};
		case ActionType.EXISTING_EMPLOYEE:
			return {
				...state,
				existingEmployees: action.payload.existingEmployees,
			};
		case ActionType.EMPLOYEE_WITH_ERROR:
			return {
				...state,
				employeesWithError: action.payload.employeesWithError,
			};
		default:
			throw new Error();
	}
};

let lastQueryParams: EmployeeQueryParams;

const EmployeeActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	navigate: NavigateFunction,
): IEmployeeActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		setExistingEmployees(existingEmployees: IEmployee[]) {
			dispatch({ type: ActionType.EXISTING_EMPLOYEE, payload: { existingEmployees } });
		},
		setEmployeesWithError(employeesWithError: IEmployeeError[]) {
			dispatch({ type: ActionType.EMPLOYEE_WITH_ERROR, payload: { employeesWithError } });
		},
		getCompanies() {
			actions.setLoading(true);
			getCompanies().then((response) => {
				dispatch({
					type: ActionType.COMPANY,
					payload: {
						companies: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao carregar empresas.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		getBranches(companyId: string) {
			actions.setLoading(true);
			getBranches({ companyId }).then((response) => {
				dispatch({
					type: ActionType.BRANCH,
					payload: {
						branches: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Erro ao carregar filiais.', {
					variant: 'error',
				});
			}).finally(() => actions.setLoading(false));
		},
		getEmployees(params: EmployeeQueryParams) {
			actions.setLoading(true);
			const take = params.take ?? 10;
			const skip = params.skip ?? 0;
			const queryParams = { ...params, skip: skip * take };
			getEmployees(queryParams)
				.then((response) => {
					dispatch({
						type: ActionType.EMPLOYEE,
						payload: {
							employees: response.data.data,
							pages: response.data.count,
							take,
							page: skip,
						},
					});
					lastQueryParams = queryParams;
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu ao obter as empresas. Tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		createEmployeesFromXls(data: FormData) {
			actions.setLoading(true);
			createEmployeesFromXls(data)
				.then((response) => {
					dispatch({
						type: ActionType.EMPLOYEES,
						payload: {
							employeesWithError: response.data.errors,
							existingEmployees: response.data.existingEmployees,
							successMessage: response.data.message,
						},
					});
					actions.getEmployees(lastQueryParams);
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu ao criar as empresas. Tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		downloadEmployeeXls() {
			actions.setLoading(true);
			downloadEmployeeXls()
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'employees.xls');
					document.body.appendChild(link);
					link.click();
					link.remove();
					enqueueSnackbar('Arquivo baixado com sucesso!', { variant: 'success' });
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao baixar o arquivo. Tente novamente.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		handleEdit(id: string) {
			navigate(`edit/${id}`);
		},
		deleteEmployee(employeeId: string) {
			actions.setLoading(true);
			deleteEmployee(employeeId)
				.then((response) => {
					enqueueSnackbar(response?.data.message || 'Colaborador excluído com sucesso!', { variant: 'success' });
					actions.getEmployees({
						skip: 0,
						take: lastQueryParams.take,
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
		},
		createEmployeesBatch(employees: IEmployee[]) {
			actions.setLoading(true);
			createEmployeesBatch(employees).then((response) => {
				enqueueSnackbar(response?.data.message || 'Colaboradores cadastrados com sucesso!', { variant: 'success' });
				actions.setExistingEmployees([]);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => {
				actions.setLoading(false);
			});
		},
	};

	return actions;
};

const Employee = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const actions = useMemo(
		() => EmployeeActions(dispatch, enqueueSnackbar, navigate),
		[dispatch, enqueueSnackbar, navigate],
	);
	return (
		/* eslint-disable react/jsx-props-no-spreading */
		<EmployeePresentational {...state} {...actions} />
	);
};

export default Employee;
