import { ConsumptionType } from '../../enums/ConsumptionType';
import { InventoryTaskAction } from '../../enums/InventoryTaskAction';
import { InventoryTaskDiff } from '../../enums/InventoryTaskDiff';
import { InventoryTaskStatus } from '../../enums/InventoryTaskStatus';
import { ProductionType } from '../../enums/ProductionType';
import { ConsumptionProductionTaskQueryParams } from '../../interfaces/ConsumptionProductionTaskQueryParams';

export interface ConsumptionProductionTask {
	id: string;
	quantity: number;
	quantityCounted: number | null;
	action: InventoryTaskAction;
	status: InventoryTaskStatus;
	diff: InventoryTaskDiff | null;
	productCode: string;
	productBarCode: string;
	productDescription: string;
	primaryMeasureDescription: string | null;
	secundaryMeasureDescription: string | null;
	primaryMeasureId: string | null;
	secundaryMeasureId: string | null;
	productConversionIndex: number | null;
	productConversionType: number | null;
	locationDestinyBarCode: string;
	locationOriginBarCode: string;
}

export interface IConsumption {
	id: string;
	code: number;
	createdAt: Date | string;
	operatorName: string;
	tasks: ConsumptionProductionTask[];
	selected?: boolean;
	employeeId?: string;
}

export interface IProduction {
	id: string;
	code: number;
	type: ProductionType;
	createdAt: Date | string;
	operatorName: string;
	tasks: ConsumptionProductionTask[];
	selected?: boolean;
}

export interface ConsumptionTasksResponse {
	data: IConsumption[];
	count: number;
}

export interface CreateConsumptionTasksParams {
	operatorName?: string;
	type: ConsumptionType;
	locationId: string;
	tasks: {
		quantity: number;
		productId: string;
	}[];
	notes?: string;
	employeeId?: string;
}

export interface StatusCounts {
	[key: string]: number;
	pending: number;
	inProgress: number;
	completed: number;
	canceled: number;
}

export interface IStatusCount<T = StatusCounts> {
	requisition: T;
	devolution: T;
}

export interface SignatureResponse {
	signature: string | null;
}

export const defaultConsumptionTaskQueryParams: ConsumptionProductionTaskQueryParams = {
	skip: 0,
	take: 10,
	action: InventoryTaskAction.CONSUMPTION,
};

export const consumptionTypeFilter = [
	{
		id: '0',
		label: 'Requisição',
		value: String(ConsumptionType.REQUISITION),
	},
	{
		id: '1',
		label: 'Devolução',
		value: String(ConsumptionType.DEVOLUTION),
	},
];
