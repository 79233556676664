export const drawerWidth = 360;

export const NOT_MENU = [
	'BUDGET_APPROVER',
	'BUDGET_RESPONSIBLE',
	'BUDGET_VALUE_TRANSFER',
	'ORDER',
	'PROJECT_TASK',
	'HOLIDAY',
	'PRODUCTION',
];

export const moduleGroups = {
	masterData: ['PRODUCT', 'BUSINESS_PARTNER', 'EMPLOYEE', 'EPI_CONTROL'],
	budget: ['BUDGET'],
	warehouse: ['INVENTORY', 'INVENTORY_TASK', 'LOCATION', 'LOCATION_TYPE', 'INVOICE', 'STATUS_SALE_ORDER', 'CONSUMPTION'],
	pricing: ['SALE_PRICE_FORMATION', 'SALE_ORDER_SIMULATION', 'SALE_ORDER'],
	hours: ['HOUR_PROJECT', 'HOLIDAY', 'TASK_CLOCK_IN_OUT', 'PROJECT_TASK'],
};

export const adminModules = ['ACCESS_GROUP', 'BRANCH', 'COMPANY', 'LICENSE', 'USER'];

export type GroupKey = 'masterData' | 'budget' | 'warehouse' | 'pricing' | 'hours';
