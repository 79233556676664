import * as yup from 'yup';
import { EpiControlQueryParams } from '../../interfaces/EpiControlQueryParams';

export interface IEmployeeWithEpis {
  id: string;
  registration: string;
  fullName: string;
  birthDate: Date;
  admissionDate: Date;
  department: string;
  costCenter: string;
  position: string;
  registrationCompanyName: string;
  registrationBranchName: string;
  lastReplacementEpi?: string;
  ePIControls: IEpiControlItem[];
}

export interface IEpiControlItem {
  id: string;
  productCode: string;
  productDescription: string;
  primaryMeasureDescription: string;
  quantity: number;
  history: string;
  lastReplacement?: string;
  caValidity?: number;
  replacementTime?: number;
  replacementTimeUnit?: number;
  notes?: string;
}

export interface EpiControlResponse {
  data: IEmployeeWithEpis[];
  count: number;
}

export interface CreateEpiControlParams {
  employeeId: string;
  productId: string;
  quantity?: number;
  history?: string;
  lastReplacement?: string;
  notes?: string;
}

export interface EditEpiControl {
  employeeId: string;
  productId: string;
  quantity?: number;
  lastReplacement?: string;
  notes?: string;
}

export interface EpiUpsertItem {
  id?: string;
  productId: string;
  quantity?: number;
  lastReplacement?: string;
  notes?: string;
}

export interface UpsertEmployeeEpi {
  epis: EpiUpsertItem[];
}

export interface IEmployeeEpi {
  id: string;
  productId: string;
  productCode: string;
  productDescription: string;
  quantity: number;
  history: string;
  active: boolean;
  lastReplacement?: string;
  notes?: string;
  caValidity?: number;
  replacementTime?: number;
  replacementTimeUnit?: number;
  primaryMeasureDescription?: string;
}

export interface IEmployeeWithEpi {
  employeeId: string;
  employeeRegistration: string;
  employeeFullName: string;
  employeeDepartment: string;
  employeePosition: string;
  employeeRegistrationBranchName: string;
  employeeRegistrationCompanyName: string;
  epis: IEmployeeEpi[];
}

export interface IEpiControlDetail {
  data: IEmployeeWithEpi;
}

export interface DeliveryHistoryEntry {
  type: 'delivery';
  date: string;
  epiCode: string;
  epiDescription: string;
  consumptionId: string;
  consumptionCode: string;
}

export interface UpdateHistoryEntry {
  type: 'update';
  action: 'created' | 'updated' | 'activated' | 'deactivated';
  user: string;
  date: string;
}

export type HistoryEntry = DeliveryHistoryEntry | UpdateHistoryEntry;

export const initialValues: IEmployeeWithEpi = {
	employeeId: '',
	employeeRegistration: '',
	employeeFullName: '',
	employeeDepartment: '',
	employeePosition: '',
	employeeRegistrationBranchName: '',
	employeeRegistrationCompanyName: '',
	epis: [],
};

export const initialFilterValues: EpiControlQueryParams = {
	skip: 0,
	take: 10,
};

export const initialAddEpiState = {
	productId: '',
	quantity: 1,
	notes: '',
};

export const validationSchema = yup.object().shape({
	epis: yup.array().of(
		yup.object().shape({
			productId: yup.string().required('Produto é obrigatório'),
			notes: yup.string().max(100, 'Observações devem ter no máximo 100 caracteres'),
		}),
	),
});

export const addEpiValidationSchema = yup.object().shape({
	productId: yup.string().required('Selecione um produto EPI'),
	quantity: yup.number().required('Quantidade é obrigatória').min(1, 'Quantidade deve ser pelo menos 1'),
	notes: yup.string().max(100, 'Observações devem ter no máximo 100 caracteres'),
});
