import React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Box,
	Typography,
} from '@mui/material';
import { Loading } from './Loading';
import Button from './Button/Button';

interface SignatureModalProps {
  open: boolean;
  onClose: () => void;
  signature?: string | null;
}

const SignatureModal = ({
	open,
	onClose,
	signature,
}: SignatureModalProps): JSX.Element => (
	<Dialog
		open={open}
		onClose={onClose}
		fullWidth
	>
		<DialogTitle>
			Assinatura do Colaborador
		</DialogTitle>
		<DialogContent dividers>
			{!signature && (
				<Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
					<Loading />
				</Box>
			)}
			{signature && (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
					p={2}
				>
					<img
						src={`data:image/png;base64,${signature}`}
						alt="Assinatura do colaborador"
						style={{
							maxWidth: '100%',
							border: '1px solid #eee',
							borderRadius: '4px',
							padding: '16px',
							backgroundColor: '#f9f9f9',
						}}
					/>
				</Box>
			)}
			{signature === null && (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					minHeight={200}
				>
					<Typography color="text.secondary">
						Assinatura não encontrada ou indisponível.
					</Typography>
				</Box>
			)}
		</DialogContent>
		<DialogActions>
			<Button onClick={onClose} color="primary" variant="contained">
				Fechar
			</Button>
		</DialogActions>
	</Dialog>
);

SignatureModal.defaultProps = {
	signature: undefined,
};

export default SignatureModal;
